


























































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";
import EditPage from "@/components/EditPage";
import BaseSyncSchedule from "../../../components/baseSyncSchedule.vue";
import CreateList from "@/components/CreateList";
import Constants from "@/Constants";
@Component
export default class Abwesenheiten extends EditPage {
  // @Prop() public aic: any;
  private showStellvertreter: boolean = false;
  private showAbteilung: boolean = false;
  private showDienstplan: boolean = false;
  private showReiseplanung: boolean = false;
  private showVCalendar: boolean = false;
  private showICS: boolean = false;
  private toggleSight: any = "ich";
  protected group: any = {};
  protected query: string = "";
  protected editorComponent: string = "";
  protected allowDragAndDrop: boolean = false;
  protected currentView: string = "Month";
  protected showQuickInfo: boolean = false;
  protected showMonth: boolean = false;
  protected showTimeLineMonth: boolean = false;
  protected showTimeLineYear: boolean = false;
  protected showDay: boolean = false;
  protected showGroup: boolean = false;
  protected queryMitarbeiter: string = "";
  protected group2: string = "";
  protected readBewilligt: boolean = false;
  protected readOnly: boolean = false;
  protected onlyEdit: boolean = false;
  protected saveModell: string = "";
  private reloadGroup: boolean = false;
  private gotoToday: boolean = false;
  private componentKey: number = 0;
  private abwZeitraum: Date | undefined = this.$api.zeitbereich.von;
  private dienstplan: boolean = false;

  protected setMinDate: boolean = false;
  private loading: boolean = false;
  private loading1: boolean = false;
  private loading2: boolean = false;
  private loading3: boolean = false;
  private loading4: boolean = false;
  private disabled: boolean = false;
  private loader: any = null;
  private persistence: boolean = false;
  protected showWeek: boolean = false;
  protected abteilung: boolean = false;
  private showBack: boolean = false;
  private anzeigeStunden: boolean = false;

  private hideMeForRefresh = false;

  public created() {
    if (this.siteData?.toggleUebergabe) {
      this.toggleSight = this.siteData.toggleUebergabe;
    } else if (
      !this.checkBegriff("WebPlanung") &&
      this.checkBegriff("Web_Uebersicht_persReisen")
    ) {
      this.toggleSight = "Reiseplanung";
    } else if (localStorage.activeTab) {
      if (
        localStorage.activeTab !== "undefined" &&
        localStorage.activeTab !== "" &&
        localStorage.activeTab !== "null"
      ) {
        const activeTab = window.localStorage.getItem("activeTab");
        // wurde activeTab falsch gesetzt - dann NICHt verwenden!
        if (
          activeTab === "ich" ||
          activeTab === "Stellvertreter" ||
          activeTab === "Abteilung" ||
          activeTab === "Dienstplan" ||
          activeTab === "Reiseplanung"
        ) {
          this.toggleSight = activeTab;
        } else {
          // ist activeTab - falsch - dann umsetzen
          localStorage.activeTab = "ich";
        }
      }
      if (this.toggleSight === "Dienstplan") {
        this.abwZeitraum = new Date();
      }
    }
    this.changeView();
  }

  public mounted() {
    if (this.siteData?.showBack) {
      this.showBack = this.siteData.showBack;
    }
    if (this.checkBegriff("Web_Stellvertreter")) {
      this.showStellvertreter = true;
    }
    if (this.checkBegriff("Web_Abwesenheiten_Uebersicht")) {
      this.showAbteilung = true;
    }
    if (this.checkBegriff("Web_Uebersicht_Dienstplan")) {
      this.showDienstplan = true;
    }
    if (this.checkBegriff("Web_Uebersicht_persReisen")) {
      this.showReiseplanung = true;
    }
  }
  private onZeitraum(newZeitraum: any) {
    this.abwZeitraum = newZeitraum;
  }
  private onloaded(loaded: boolean, gotoAic: string) {
    if (loaded) {
      setTimeout(() => {
        this.loading = false;
        this.loading1 = false;
        this.loading2 = false;
        this.loading3 = false;
        this.loading4 = false;
        this.disabled = false;
      }, 300);
    }
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }
  private forceRerender() {
    this.componentKey += 1;
  }
  private changeView() {
    const me = this;
    this.loading = false;
    this.loading1 = false;
    this.loading2 = false;
    this.loading3 = false;
    this.loading4 = false;
    this.disabled = true;
    this.hideMeForRefresh = true;

    const schedule = this.$refs.schedule as BaseSyncSchedule;
    // akt. Tab merken für refresh!
    const activeTab = window.localStorage.setItem("activeTab", this.toggleSight);
    if (this.toggleSight === "Stellvertreter") {
      this.anzeigeStunden = false;
      this.loading1 = true;
      this.showVCalendar = false;
      this.query = "Web_Planung_StellvertreterSicht";
      this.editorComponent =
        "PzePersoenlichPlanungenTemplatesDialogFreigabeStellvertreter";
      if (Constants.isMobile) {
        this.currentView = "Month";
        this.showTimeLineMonth = false;
        this.group = {
          enableCompactView: true,
          resources: ["employee"],
        };
      } else {
        this.currentView = "TimelineMonth";
        this.showTimeLineMonth = true;
        this.group = {
          enableCompactView: false,
          resources: ["employee"],
        };
      }
      this.persistence = false;
      this.showQuickInfo = true;
      this.showMonth = false;
      this.showWeek = false;
      this.showGroup = true;
      this.queryMitarbeiter = "Web_ListeMA_Stellvertreter";
      this.readBewilligt = true;
      this.saveModell = "";
      this.readOnly = false;
      this.formular = "";
      this.allowDragAndDrop = false;
      this.setMinDate = true;
      this.group2 = "";
      this.onlyEdit = true;
      this.abteilung = false;
      this.showICS = false;
      this.dienstplan = false;
    } else if (this.toggleSight === "ich") {
      this.anzeigeStunden = false;
      this.loading = true;
      this.showVCalendar = false;
      this.showICS = this.checkBegriff("ICS_Export");
      this.query = "Web_WebPlanungMA";
      this.editorComponent = "PzePersoenlichPlanungenTemplatesDialog";
      this.currentView = "Month";
      this.persistence = false;
      this.showQuickInfo = false;
      this.showMonth = true;
      this.showWeek = true;
      if (!Constants.isMobile) {
        this.showTimeLineYear = true;
        this.showDay = true;
      }
      this.showGroup = false;
      this.group = {};
      this.queryMitarbeiter = "";
      this.readBewilligt = false;
      this.saveModell = "WEB_PZE_PlanungDoppelbuchungen";
      this.readOnly = false;
      this.formular = this.$globalsKennung(this.Globals.Begriff, "Workflow_MA_Planung");
      this.allowDragAndDrop = false;
      this.setMinDate = false;
      this.showTimeLineMonth = false;
      this.group2 = "";
      this.onlyEdit = false;
      this.gotoToday = false;
      this.abteilung = false;
      this.dienstplan = false;
    } else if (this.toggleSight === "Abteilung") {
      this.anzeigeStunden = false;
      this.loading2 = true;
      this.showVCalendar = false;
      this.reloadGroup = true;
      this.gotoToday = true;
      if (Constants.isMobile) {
        this.persistence = true;
        this.currentView = "Month";
        this.showMonth = true;
        this.showTimeLineMonth = false;
        this.group2 = "";
        this.group = {
          enableCompactView: true,
          resources: ["employee"],
        };
      } else {
        this.showWeek = false;
        this.showMonth = false;
        this.persistence = false;
        this.currentView = "TimelineMonth";
        this.showTimeLineMonth = true;
        this.group2 = "WebWF_MA_Gruppiert_Kostenstelle"; // alle MA nicht nur die mit Planungen
        // this.group2 = "Web_Gruppierung_KST";
        this.group = {
          enableCompactView: false,
          resources: ["GroupData", "Employee"],
        };
      }
      this.showGroup = true;
      this.setMinDate = false;
      this.query = "Web_FehlzeitenUebersicht";
      this.editorComponent = "";
      this.showQuickInfo = true;
      this.queryMitarbeiter = "Web_Liste_MA_Abwesenheiten";
      this.readBewilligt = true;
      this.saveModell = "";
      this.readOnly = true;
      this.allowDragAndDrop = false;
      this.onlyEdit = true;
      this.abteilung = true;
      this.formular = this.$globalsKennung(
        this.Globals.Begriff,
        "Web_Abwesenheiten_Uebersicht"
      );
      this.showICS = false;
      this.dienstplan = false;
    } else if (this.toggleSight === "Dienstplan") {
      this.anzeigeStunden = true;
      this.showVCalendar = false;
      this.loading3 = true;
      this.reloadGroup = true;
      this.gotoToday = true;
      // this.currentView = "week";
      this.currentView = "TimelineWeek";
      let von = new Date();

      von = new Date(von.firstOfWeek());
      const bis = von.addDays(+6);
      const bereich = "Woche";
      // ich stelle hier schon auf Woche - damit auch die QUERY Abfrage sauber für die eine woche geholt wird
      this.zeitbereich = {
        von,
        bis,
        bereich,
      };
      this.group2 = ""; // alle MA nicht nur die mit Planungen
      // this.group2 = "Web_Gruppierung_KST";
      this.group = {
        enableCompactView: false,
        resources: ["GroupData", "Employee"],
      };
      this.showGroup = true;
      this.showWeek = true;
      this.showMonth = false;
      this.showTimeLineMonth = false;
      this.setMinDate = false;
      this.queryMitarbeiter = "Web_ZE_Dienstplan_ListeMA";
      this.query = "Web_ZE_TGM_Schichtplanung";
      this.editorComponent = "";
      this.showQuickInfo = true;
      this.persistence = false;
      this.readBewilligt = true;
      this.saveModell = "";
      this.readOnly = true;
      this.allowDragAndDrop = false;
      this.onlyEdit = true;
      this.abteilung = true;
      this.allowDragAndDrop = false;
      this.formular = this.$globalsKennung(
        this.Globals.Begriff,
        "Web_Uebersicht_Dienstplan"
      );
      this.showICS = false;
      this.showDay = true;
      this.dienstplan = true;
    } else if (this.toggleSight === "Reiseplanung") {
      this.anzeigeStunden = false;
      this.showVCalendar = true;
      this.loading4 = true;
      this.reloadGroup = false;
      this.gotoToday = true;
      this.currentView = "month";
      this.group2 = "";
      this.group = {};
      this.showMonth = true;
      this.showWeek = true;
      this.showGroup = false;
      this.setMinDate = false;
      this.query = "Web_Planung_Reisen";
      this.editorComponent = "RkMitarbeiterTemplateDialogReisen";
      this.showQuickInfo = true;
      this.persistence = false;
      this.queryMitarbeiter = "";
      this.readBewilligt = true;
      this.saveModell = "";
      this.readOnly = true;
      this.allowDragAndDrop = false;
      this.gotoToday = true;
      this.onlyEdit = true;
      this.abteilung = false;
      this.formular = this.$globalsKennung(
        this.Globals.Begriff,
        "Web_Uebersicht_persReisen"
      );
      this.showICS = false;
      this.dienstplan = false;
    }
    this.$nextTick(() => {
      this.hideMeForRefresh = false;
      // setTimeout(() => {
      //   this.loading = false;
      //   this.loading1 = false;
      //   this.loading2 = false;
      //   this.disabled = false;
      // }, 300);
    });
  }
}
