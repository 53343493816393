




























































































import { getKZColumn, getValue } from "@/DataHelper";
import { findKennungSperre } from "@/SperreHelper";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  AufgabeStatus,
  AufgabeStatusContainer,
  AufgabeStatusZuordnung,
  AufgabeStatusZuordnungAufgabe,
  AufgabeStatusFrame,
  callQuickviewSelectionCallBack,
} from "./ChartTypesAndFunctions";
declare type AufgabeItemMode = "Nebeneinander" | "QuickView" | "Tab";
@Component
export default class AufgabenBoardItem extends Vue {
  @Prop() public aufgabe!: ALLAufgabe;
  @Prop() public zeitbereich!: APIZeitbereich;
  @Prop({ default: "vertical" }) public orientation!: ItemOrientation;
  @Prop() public aufgabenDefinition?: QueryResponse;
  @Prop() public size!: number;
  @Prop() public aic!: number;

  @Prop() public aufgabeStatus?: AufgabeStatus[];
  @Prop({ default: false }) private persoenlich!: boolean;

  private activeTabIndex: number = 0;

  // daten bevor sie gefiltert wurden
  private filterData: QueryResponse | null = null;
  // fertig gefilterte Daten...
  private filteredData: QueryResponse | null = null;

  private showLoading: boolean = false;
  private waitingForQVAics: boolean = false;

  // gekapselt damit es nur einen Modus geben KANN (nicht mixed mist)
  private Mode: AufgabeItemMode = "Nebeneinander";
  private get QuickViewMode(): boolean {
    return this.Mode === "QuickView";
  }
  private get TabMode(): boolean {
    return this.Mode === "Tab";
  }

  private QvAICs: number[] = [];
  private statusListe: AufgabeStatusContainer[] = [];

  private QVZuordnungen: AufgabeStatusZuordnung[] = [];
  private nextAbschluss = "";
  private vector: number[] = [];

  private page: number = 0;
  private pageSize: number = 10;
  private showTitle: boolean = true;

  public mounted() {
    this.refresh();
  }
  // @Watch("size")
  // private resize() {
  //   this.refresh();
  // }
  @Watch("aufgabe")
  @Watch("aufgabeStatus")
  private async refresh() {
    this.showLoading = true;
    if (this.aufgabe) {
      const response = await this.$api.getStatusAufgaben(this.aufgabe.aic);
      if (response.status > 210) {
        alert("keine Daten gefunden!");
      }
      const status = response.data as AufgabeStatus[];
      this.refreshAufgabeStatus(status);
    } else if (this.aufgabeStatus) {
      this.refreshAufgabeStatus(this.aufgabeStatus);
    }
  }
  private refreshAufgabeStatus(status: AufgabeStatus[]) {
    const qv = status.find((s) => s.darstellungKennung === "QuickView");

    // qv rauslösen... und in QuickViewMode schalten
    if (qv) {
      this.waitingForQVAics = true;
      this.QVZuordnungen = qv.zuordnung;
      this.Mode = "QuickView";
      this.$emit("hideAufgabeTitle"); // titel verstecken
    } else if (this.aufgabe.tab) {
      this.Mode = "Tab";
    }
    this.statusListe = status.map((s) => {
      const as: AufgabeStatusContainer = {
        status: s,
        zeitbereich: this.zeitbereich,
        orientation: this.orientation,
        params: {
          zeitbereich: this.zeitbereich,
          aufgabenDefinition: this.aufgabenDefinition,
          query: s.filterKennung,
          hsDefinition: s.hsAic,
          orientation: this.orientation,
          aic: this.aic,
          zuordnungen: s.zuordnung?.filter((z) => z.gruppe !== "Aufgabe"),
          zusatzInfo: s.zuordnung?.find(
            (z) =>
              z.gruppe === "Aufgabe" || z.gruppe === "Frame" || z.gruppe === "Hauptschirm"
          ) as AufgabeStatusZuordnungAufgabe,
          aufgabe: this.aufgabe,
        },
      };
      this.setChartType(as);

      as.params.aicStamm = as.status.stamm;

      if (qv) {
        as.params.size = 200;
      }
      if (
        as?.status?.darstellungKennung !== "Liste" &&
        as?.status?.darstellungKennung !== "KPI"
      ) {
        as.titel = s.bezeichnung;
        as.params.abwZeitbereich = s.zeitArt;
      } else if (as?.status?.darstellungKennung === "KPI") {
        as.params.title = s.bezeichnung;
        as.params.icon = s.bild;
        as.params.abwZeitbereich = s.zeitArt;
        as.params.tooltip = s.tooltip;
      }
      return as;
    });
    if (this.aufgabe?.abschlussTyp) {
      this.getSperrDate(this.aufgabe.abschlussTyp);
    }
    this.$emit("dataLoaded", {
      data: status,
    });

    this.showLoading = false;
  }

  private selectChangeAll(val: boolean) {
    callQuickviewSelectionCallBack(val);
  }

  protected getSperrDate(begriff: any) {
    const me = this;
    const text = this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_gueltig_Ab",
      "gültig ab:"
    );
    if (begriff) {
      const sperre = findKennungSperre(begriff, me);
      if (sperre && sperre.sperre.next) {
        const sperrDatum = new Date(sperre.sperre.next).addDays(-1).toLocaleDateString();
        let gueltigAb = "";
        if (sperre.sperre.ab) {
          const gueltig = new Date(sperre.sperre.ab).toLocaleDateString();
          if (gueltig) {
            gueltigAb = " " + text + " " + gueltig;
          }
        }
        this.nextAbschluss = sperre.sperre.bezeichnung + ": " + sperrDatum + gueltigAb;
      }
    }
    return null;
  }

  private setChartType(as: AufgabeStatusContainer) {
    switch (as.status.darstellungKennung) {
      case "Balken_Chart":
        as.params.chartType = "Bar";
        as.params.chartOrientation = "vertical";
        break;
      case "Balken_Horizontal":
        as.params.chartType = "Bar";
        as.params.chartOrientation = "horizontal";
        break;
      case "BalkenStackedMinMax":
        as.params.chartType = "BalkenStackedMinMax";
        as.params.chartOrientation = "horizontal";
        break;
      case "Chart_Kreis":
        as.params.chartType = "Pie";
        break;
      case "Tabelle":
      case "Liste":
      case "Kalender":
      case "QuickView":
        break;
    }
  }

  private get pages() {
    const count = Math.floor(this.AllQvAics.length / this.pageSize);
    const rest = this.AllQvAics.length % this.pageSize;
    return count + (rest > 0 ? 1 : 0);
  }

  protected setPage(ix: number) {
    const start = this.pageSize * ix;
    this.QvAICs = this.AllQvAics.slice(start, start + this.pageSize);
    this.page = ix;
  }
  private AllQvAics: number[] = [];
  private quickViewAicsLoaded(aics: number[]) {
    this.pageSize = this.aufgabe.pagAnzahl ?? this.pageSize;
    this.AllQvAics = aics;
    this.setPage(0);
    this.waitingForQVAics = false;
    this.$forceUpdate();
  }

  private vectorChanged(vec: number[]) {
    this.vector = vec;
  }

  private filteredDataChanged(filteredData: QueryResponse) {
    this.filteredData = filteredData;
    const aicCol = getKZColumn("modellAic", filteredData.columns);

    // neue aics rausfiltern
    const aics = filteredData.data.map((row) => {
      let aic = 0;
      if (aicCol) {
        aic = getValue(row, aicCol);
      }
      if (!aic) {
        if (row.aic_Bew_pool) {
          aic = row.aic_Bew_pool;
        } else {
          aic = row.aic_Stamm;
        }
      }
      return aic;
    });

    // neue AicsListe setzen
    this.QvAICs = aics;
  }

  private getStatusColumns(as: AufgabeStatus, ix: number) {
    if (this.Mode === "Tab") {
      return "col-12";
    }
    return "col-" + 12 / this.statusListe.length;
  }
}
