export const deStrings = {
    dropdowns: {
        noRecordsTemplate: "Keine Daten gefunden",
        actionFailureTemplate: "Daten konnten nicht geladen werden"
    },
    daterangepicker: {
        placeholder: "Wählen Sie einen Bereich aus",
        startLabel: "Wählen Sie Startdatum",
        endLabel: "Wählen Sie Enddatum",
        applyText: "Übernehmen",
        cancelText: "Abbruch",
        selectedDays: "Ausgewählte Tage",
        days: "Tage",
        thisWeek: "aktuelle Woche",
        thisMonth: "aktueller Monat",
        lastMonth_3: "letzter Monat",
        lastYear_4: "letztes Jahr",
        customRange: "benutzerdefiniert",
        today: "heute"
    },
    datepicker: {
        placeholder: "Wählen Sie ein Datum aus",
        today: "heute"
    },
    grid: {
        EmptyRecord: "Keine Aufzeichnungen angezeigt",
        GroupDropArea: "Ziehen Sie einen Spaltenkopf hier, um die Spalte zu gruppieren",
        UnGroup: "Klicken Sie hier, um die Gruppierung aufheben",
        EmptyDataSourceError: "kein Daten gefunden",
        Item: "Artikel",
        Items: "Artikel",
        Add: "Neu",
        Delete: "Löschen",
        Update: "Speichern",
        CancelButton: "Abbruch",
        OkButton: "Ok",
        Edit: "Bearbeiten",
        Print: "Drucken",
        Columns: "Spalten",
        ChooseColumns: "Wählen Sie",
        SelectAll: "Alle",
        Search: "Suche",
        ClearFilter: "Filter entfernen"
    },
    pager: {
        currentPageInfo: "{0} von {1} Seiten",
        totalItemsInfo: "({0} Beiträge)",
        firstPageTooltip: "Zur ersten Seite",
        lastPageTooltip: "Zur letzten Seite",
        nextPageTooltip: "Zur nächsten Seite",
        previousPageTooltip: "Zurück zur letzten Seite",
        nextPagerTooltip: "Zum nächsten Pager",
        previousPagerTooltip: "Zum vorherigen Pager"
    },
    schedule: {
        day: "Tag",
        week: "Woche",
        workWeek: "Arbeitswoche",
        timelineWeek: "TimeLine Week",
        month: "Monat",
        agenda: "Agenda",
        weekAgenda: "Wochen Agenda",
        workWeekAgenda: "Arbeitswoche - Agenda",
        monthAgenda: "Monats -  Agenda",
        today: "heute",
        noEvents: "keine Daten",
        emptyContainer: "Keine Daten gefunden.",
        allDay: "ganzer Tag",
        start: "Start",
        end: "Ende",
        more: "mehr",
        close: "Schliessen",
        cancel: "Abbruch",
        noTitle: "(kein Titel)",
        delete: "Löschen",
        deleteEvent: "Lösche Eintrag",
        deleteMultipleEvent: "Lösche mehrere Einträge",
        selectedItems: "gewähltes Objekt",
        deleteSeries: "Lösche Serie",
        edit: "Bearbeiten",
        editSeries: "Serie bearbeiten",
        editEvent: "bearbeiten",
        createEvent: "neu",
        subject: "Betreff",
        addTitle: "neuer Titel",
        moreDetails: "Details",
        save: "Speichern",
        editContent: "Möchten Sie nur dieses Ereignis bearbeiten oder die ganze Serie?",
        deleteRecurrenceContent:
            "Möchten Sie nur dieses Ereignis löschen oder die ganze Serie?",
        deleteContent: "Sind Sie sicher, dass Sie dieses Ereignis löschen möchten?",
        deleteMultipleContent:
            "Sind Sie sicher, dass Sie die ausgewählten Ereignisse löschen möchten?",
        newEvent: "Neues Ereignis",
        title: "Titel",
        saveButton: "Speichern",
        cancelButton: "Abbruch",
        deleteButton: "Löschen"
    }
};
