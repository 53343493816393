// import { numberingSystems } from 'cldr-data/main/de/numberingSystems.json';
import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "./UIHelper";
import AUApi from "@/api";
import { AxiosResponse } from "axios";
import { getKZValue } from "@/DataHelper";
import { getGeoLocation } from "./GeoHelper";

export interface CalcResult {
  success: "success" | "warning" | "error";
}

export interface VarUebergabe {
  name: string;
  var?: string;
  value?: any;
  wert?: any;
  type?: "string" | "int" | "boolean";
}

declare interface VarUebergabeApi {
  var: string;
  type: "string" | "int" | "boolean" | "gps" | "double";
  wert: any;
  art: number;
  user: string;
  perm: boolean;
}
/**
 * @deprecated The method should not be used please use the new one
 */
export async function calcFunction(
  modell: string,
  aic: number,
  fromDialog: boolean = false,
  app: Vue,
  zeitbereich: any,
  variable?: any,
  varName?: string,
  varDatum?: string,
  varName2?: string,
  varZeitart?: string,
  variable1?: any,
  text?: string,
  setShowLoading?: (show: boolean) => void
) {
  const vars: VarUebergabe[] = [];

  if (typeof (variable) === "object") {
    vars.push(variable as VarUebergabe);
  } else if (varName && variable) {
    vars.push({ name: varName, value: variable });
  }

  if (varZeitart && varName2) { vars.push({ name: varName2, value: varZeitart }); }
  if (variable1 && varDatum) {
    const value = new Date(variable1).toISOStringWithTZ();
    vars.push({ name: varDatum, value });
  }
  const result = await calcFunctionNew(modell, aic, fromDialog, app, zeitbereich, vars, [], text, setShowLoading);
  return result?.msgType;
}

export async function calcFunctionNew(
  modell: string,
  aic: number,
  fromDialog: boolean = false,
  app: Vue,
  zeitbereich: any,
  variablen: VarUebergabe[] = [],
  tabMDialog?: any[],
  text?: string,
  vecStamm?: any,
  setShowLoading?: (show: boolean) => void,
  checkGeoLocation: boolean = false,
  checkTimeZone: boolean = false,
  fromBew?: boolean,
  fromDialogQuery: QueryResponse | null = null,
  fromDialogQuestion?: string,
  calcWeiter?: number,
  calcFertig?: boolean,
  frage?: boolean,
) {

  const setLoading = (s: boolean) => setShowLoading ? setShowLoading(s) : null;
  try {
    let begriffModell!: ALLModell;
    let tabUbergabe: any[] = [];
    if (tabMDialog) {
      tabUbergabe = tabMDialog;
    }
    if (modell) {
      begriffModell = app.$globalsKennung(Globals.Begriff, modell);
    }
    // wird der Begriff nicht gefunden - ABBRUCH!
    if (!begriffModell) {
      showSnackbar({
        text: "Modell nicht gefunden",
        color: "error"
      });
      throw new Error("kein Modell");
    }
    if (begriffModell.userFrage && fromDialog !== true) {
      const userFrage = app.$globalsBegriffMemo(
        app.Globals.Begriff,
        begriffModell.userFrage,
        "Soll die Berechnung durchgeführt werden?"
      );
      showDialog({
        title: "Frage",
        titleColor: "white",
        titleClass: "black--text",
        width: 300,
        height: 400,
        text: userFrage,
        persistent: true,
        okButton: true,
        onClose: (x: any) => {
          if (x.dialogResult === true) {
            calcFunctionNew(modell, aic, true, app, zeitbereich, variablen);
          }
          return true;
        }
      });
      return;
    }
    // dialog nur aufmachen wenn dialog erforderlich und aufruf nicht vom dialog kommt.
    if (
      begriffModell.abfrage &&
      begriffModell.MDialog &&
      fromDialog !== true
    ) {
      showDialog({
        title: "Modelldialog",
        width: 250,
        site: "ModellDialog",
        titleColor: "dvhBackground",
        titleClass: "white--text",
        data: {
          zeitbereich,
          query: begriffModell.abfrage
        },
        okButton: true,
        onClose: (x: any) => {
          if (x.returnedData.length > 0) {
            tabUbergabe = x.returnedData;
          }
          if (x.dialogResult === true) {
            calcFunctionNew(modell, aic, true, app, zeitbereich, variablen, tabUbergabe);
          }
          return true;
        }
      });
      return; // nicht weitermachen nur dialog aufmachen
    }
    if (
      (fromDialogQuery || fromDialogQuestion)
    ) {
      if (fromDialogQuestion) {
        const userFrage = fromDialogQuestion;
        showDialog({
          title: "Frage",
          titleColor: "white",
          titleClass: "black--text",
          width: 300,
          height: 400,
          text: userFrage,
          persistent: true,
          okButton: true,
          onClose: (x: any) => {
            if (x.dialogResult === true) {
              calcFunctionNew(modell, aic, false, app, zeitbereich, variablen, tabUbergabe, "", [],
                setShowLoading, false, false, false, null, "", calcWeiter, false, true);
            } else {
              calcFunctionNew(modell, aic, false, app, zeitbereich, variablen, tabUbergabe, "", [],
                setShowLoading, false, false, false, null, "", calcWeiter, false, false);
            }
            return true;
          }
        });
      } else {
        const y = fromDialogQuery;
        const data = { data: y };
        showDialog({
          title: "Modelldialog",
          width: 250,
          site: "ModellDialog",
          titleColor: "dvhBackground",
          titleClass: "white--text",
          data: {
            zeitbereich,
            preloadedData: data,
          },
          okButton: true,
          onClose: (x: any) => {
            if (x.returnedData?.length > 0) {
              tabUbergabe = x.returnedData;
            }
            if (x.dialogResult === true) {
              calcFunctionNew(modell, aic, true, app, zeitbereich, variablen, tabUbergabe, "", [],
                setShowLoading, false, false, false, null, "", calcWeiter);
            } else {
              calcFunctionNew(modell, aic, true, app, zeitbereich, variablen, tabUbergabe, "", [],
                setShowLoading, false, false, false, null, "", calcWeiter, true);
            }
            return true;
          }
        });
      }
      return; // nicht weitermachen nur dialog aufmachen
    }
    setLoading(true);
    const bemerkung: string = "Web - CalcHelperStatic";
    if (begriffModell?.periode !== "offen" && zeitbereich) {
      if (zeitbereich.bereich !== begriffModell.periode) {
        zeitbereich.bereich = begriffModell.periode;
      }
    }

    // map variables
    const varUbergabe: VarUebergabeApi[] = mapVariables(variablen);

    if (checkTimeZone) { // wurde
      // ein abw. Zone gesetzt & der user ist in einer abw. Zone - dann darf ich das nicht übersteuern
      const lokalOffset = new Date().getTimezoneOffset() * -1;
      const serverTime = AUApi.Current.user.time;
      const serverOffset = Date.getISOTimeZoneOffsetInMin(serverTime);
      if (lokalOffset !== serverOffset) {
        const newtimeZone = new Date('2021-01-01').getTimezoneOffset() * -1;
        varUbergabe.push({
          var: "differentTimeZone",
          type: "boolean",
          wert: true,
          art: 1,
          user: AUApi.Current.user.user,
          perm: false
        });
        varUbergabe.push({
          var: "newtimeZone",
          type: "int",
          wert: newtimeZone,
          art: 1,
          user: AUApi.Current.user.user,
          perm: false
        });
      }
    }

    if (checkGeoLocation) {
      try {
        const position = await getGeoLocation();
        const latitute = position.latitude;
        const longitude = position.longitude;
        if (latitute !== 0) {
          varUbergabe.push({
            var: "gps",
            type: "gps",
            wert: { lat: latitute, lng: longitude },
            art: 1,
            user: AUApi.Current.user.user,
            perm: false
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    let aicBew: number = 0;
    if (fromBew) {
      // Vector Bewegung??? wie übergebe ich das?
      aicBew = aic ?? vecStamm[0];
      aic = 0;
    }
    return await invokeCalc(app, varUbergabe, tabUbergabe, begriffModell, aic, zeitbereich, bemerkung, text,
      vecStamm, aicBew, calcWeiter, calcFertig, frage);
  } finally {
    setLoading(false);
  }
}
let aufgabenDefinitionCache: QueryResponse | undefined;
// gibt die aktuelle aufgabendefinion zurück cacht sie aber für die aktuelle session
export async function getAufgabeDefinition(): Promise<QueryResponse> {
  if (aufgabenDefinitionCache) { return aufgabenDefinitionCache; }
  try {
    const response = await AUApi.Current.getQuery(
      "Web_Status_Definitionen",
    ) as AxiosResponse<QueryResponse>;

    if (response.status < 300 && !response.data.error) {
      return aufgabenDefinitionCache = response.data;
    }
    if (response?.data.error) {
      console.error("error getting aufgabedefinion!", response?.data.error);
    }
  } catch (e) {
    console.error("error getting aufgabedefinion!", e);
  }
  return { header: [], columns: [], data: [], error: "no data from server" };
}

export function getKZAufgabeDefiniton<T>(
  kz: string,
  aicStamm: number,
  aufgabenDefinition: QueryResponse,
  defaultVal?: T) {
  const aufgabenDefRow = getAufgabeDefRow(aicStamm, aufgabenDefinition);
  if (!aufgabenDefRow) { return defaultVal; }
  return getKZValue(aufgabenDefRow, kz, aufgabenDefinition?.columns) ?? defaultVal;
}
export function getAufgabeDefRow(aicStamm: number, aufgabenDefinition: QueryResponse) {
  if (!aufgabenDefinition) { return; }
  return aufgabenDefinition?.data.find(
    (r: any) => r.aic_Stamm === aicStamm
  );
}

function mapVariables(variablen: VarUebergabe[]) {
  const varUbergabe: VarUebergabeApi[] = [];
  if (variablen) {
    variablen.forEach(v => {
      const x: VarUebergabeApi = {
        var: v.name ?? v.var,
        type: v.type ?? "string",
        wert: v.value ?? v.wert,
        art: 1,
        user: AUApi.Current.user.user,
        perm: false
      };
      varUbergabe.push(x);
    });
  }
  return varUbergabe;
}
// im PostCalc gibt es noch bewAic.. fehlt hier noch bei der Übergabe!
async function invokeCalc(
  app: Vue,
  varUbergabe: VarUebergabeApi[],
  tabUbergabe: any[],
  begriffModell: ALLModell,
  aic: number,
  zeitbereich: any,
  bemerkung: string,
  text?: string,
  vecStamm?: any,
  aicBew?: number,
  calcWeiter?: number,
  calcFertig?: boolean,
  frage?: boolean) {
  let res;
  // wurde modellDialog mit ja beantwortet dann geht es weiter - sonst Modell beenden!
  if (calcWeiter) {
    let frageUebergabe = 0;
    if (frage) {
      // komme ich aus einer Frage - und wurde dise mit Ja beantwortet muss ich das weiter geben!
      frageUebergabe = 1;
    }
    if (calcFertig) {
      res = await app.$api.postCalcFertig(calcWeiter, null
      );
    } else {
      res = await app.$api.postCalcWeiter(calcWeiter,
        tabUbergabe, frageUebergabe, null,
      );
    }
  } else {
    res = await app.$api.postCalc(
      varUbergabe,
      tabUbergabe,
      begriffModell.aic,
      aic,
      zeitbereich,
      0,
      0,
      bemerkung,
      null,
      begriffModell.maxB,
      vecStamm,
      aicBew
    );
  }

  let calcResult: string = "";
  let iconAmpel: string = "";

  if (res.data.ergebnis !== "") {
    calcResult = res.data.ergebnis;
  }
  let ampelNumber: string = "";
  if (begriffModell.abfrage) {
    if (calcResult === "Gelb") {
      ampelNumber = "2";
    } else if (calcResult === "Rot") {
      ampelNumber = "3";
    } else if (calcResult === "Gruen") {
      ampelNumber = "1";
    }
    iconAmpel =
      "<img src='/" +
      ampelNumber +
      "_Ampel.png' alt='" +
      ampelNumber +
      "' />";
  } else {
    iconAmpel = "";
  }

  res.data.text = text;
  const dialogAbf = res.data.MD_Abf;
  let iVB = res.data.iVB;
  const msgType = res.data.msgType;
  let dialogFrage;
  if (msgType === "Frage") {
    dialogFrage = res.data.info;
    if (!iVB) {
      iVB = calcWeiter;
    } else {
      calcWeiter = iVB;
    }
    // iVB = calcWeiter;
    const setLoading = (s: boolean) => true;
    if (!res.data.iVB) {
      // nur wenn ich schon aus einem ModellDialog zB komme - also schon WEITER bin darf ich hier
      // nochmals das CALC aufrufen!
      // kommt als erstes eine FRAGE - dann würde ich die Frage doppelt öffnen - und bleibt dann hängen!
      const resFrage = await calcFunctionNew(begriffModell.kennung, aic, true, app, zeitbereich, [], tabUbergabe,
        "", [], setLoading, false, false, false, null, dialogFrage, calcWeiter, false, true);
      if (resFrage) {
        // ... weiterr wenn res da ist...
      }
    }

  }
  // ist es der erste Teil vom Modell darf ich noich nicht "fertig" schicken...
  if (!dialogAbf && msgType !== "Frage") {
    app.$emit("calculated", res.data);
  }
  const vorfehler = res.data.vorfehler;
  if (vorfehler && vorfehler.length > 0 && vorfehler[0].error) {
    console.error("Thread - Modell Fehler: " + res.data.header.vorfehler[0].error);
    showSnackbar({
      text: vorfehler.error,
      color: "error"
    });
  }
  const calcMessage = res.data.error;
  const header = res.data.header;
  let info = res.data.info;
  const responseJSON = res.data.response;
  const memo = res.data.memo;
  const title = res.data.titel;
  if (memo) {
    info = memo;
  }
  // this.showLoading = false;
  if (msgType === "Error") {
    showSnackbar({
      text: info,
      color: "error"
    });
  } else if (msgType === "Info") {
    showSnackbar({
      text: info,
      color: "info"
    });
  } else if (msgType === "Warnung") {
    showSnackbar({
      text: info,
      color: "warning"
    });
  }
  return {
    calcResult,
    iconAmpel,
    ampelNumber,
    calcMessage,
    header,
    memo,
    title,
    msgType,
    info: res.data.info,
    dialogAbf,
    iVB,
    dialogFrage
  };
}

