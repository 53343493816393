









import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
@Component({
  provide: {},
})
export default class Kollektivvertrag extends Vue {
  @Prop({ default: null }) public siteData: any;
}
