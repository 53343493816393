import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "@/UIHelper";
import { calcFunctionNew, VarUebergabe } from "@/CalcHelperStatic";

@Component
export default class CalcHelper extends Vue {
  protected aic!: number;
  @Prop() public zeitbereich!: APIZeitbereich | null;
  @Prop({ default: true }) protected backOnSave!: boolean;
  @Prop({ default: null }) protected siteData: any;
  // protected disabled!: boolean;
  // @Prop({ default: false }) protected showResult!: boolean;
  protected begriffModell: ALLModell | null = null;
  protected tabUbergabe: any[] = [];
  protected showLoading: boolean = false;
  @Prop() protected varName!: string;
  @Prop() protected varDatum!: any;
  @Prop() protected varName2!: string;
  @Prop() protected varName3!: string;
  @Prop() protected varZeitart!: any;
  @Prop() protected variable!: any;
  @Prop() protected variable1!: any;
  @Prop() protected variable2!: any;
  @Prop() protected variable3!: any;
  @Prop() protected varObject!: any;
  @Prop({ default: false }) public checkTimeZone!: boolean;
  @Prop({ default: false }) public checkGeoLocation!: boolean;
  protected debug: number = 0;
  protected abbruch: number = 0;
  protected calcResult: string = "";
  protected calcMessage: string = "";
  protected calcInfo: string = "";
  protected calcWarnung: string = "";
  protected msgType: string = "";
  protected header: string = "";
  protected info: string = "";
  protected iconAmpel: string = "";
  protected dialogQuery: any;
  protected dialogFrage!: string ;
  @Prop() protected text!: string;

  public async calcFunction(
    modell: string,
    aic: number,
    fromDialog: boolean = false,
    vecStamm: any,
    fromBew?: boolean
  ) {
    const vars: VarUebergabe[] = [];
    if (this.varObject) {
      vars.push(this.varObject[0]);
    }
    if (this.varName || this.varName2 || this.varDatum || this.varName3) {
      if (this.variable) {
        vars.push({
          name: this.varName,
          value: this.variable,
        });
      }
      if (this.varZeitart) {
        vars.push({
          name: this.varName2,
          value: this.varZeitart,
        });
        // this.$api.setVariable(varUbergabe, this.$api.user.id, this.success); // soll das Memo übergeben
      }
      if (this.variable1) {
        let wert;
        if (this.variable3) {
          wert = this.variable3;
        } else {
          wert = new Date(this.variable1).toISOStringWithTZ();
        }
        vars.push({
          name: this.varDatum,
          value: wert,
        });
      }
      if (this.variable2) {
        vars.push({
          name: this.varName3,
          type: "int", // für Integer - Ganzzahl
          value: this.variable2,
        });
      }


    }
    const res = await calcFunctionNew(modell, aic, fromDialog, this, this.zeitbereich, vars, [], "", vecStamm,
      loading => this.showLoading = loading, this.checkGeoLocation, this.checkTimeZone && !this.variable3, fromBew);
    this.calcResult = res?.calcResult ?? "??";
    this.iconAmpel = res?.iconAmpel ?? "";
    this.calcMessage = res?.calcMessage;
    this.msgType = res?.msgType;
    this.header = res?.header;
    this.info = res?.info;
    this.dialogQuery = res?.dialogAbf;
    this.dialogFrage = res?.dialogFrage;
    const iVB = res?.iVB;
    if (this.dialogQuery || this.dialogFrage) {
     // modellDialog aufrufen..
     const resDialog = await calcFunctionNew(modell, aic, false, this, this.zeitbereich, vars, [], "", vecStamm,
     loading => this.showLoading = loading, this.checkGeoLocation, this.checkTimeZone && !this.variable3,
     fromBew, this.dialogQuery, this.dialogFrage, iVB);
    }

  }

  private showPosition(position: any) {
    console.log("Location: " + position.coords.latitude, position.coords.longitude);
    return position.coords;
  }
}
