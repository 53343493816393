













































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { createBase64Image, resizeImage } from "@/FileHelper";
import EditComponent from "@/editor/EditComponent";
import Constants from "../Constants";
import { Capacitor } from "@capacitor/core";

@Component
export default class EditBild extends EditComponent {
  constructor() {
    super();
  }
  private imageName: string = "";
  private imageFile: File | null = null;
  private loading: boolean = false;
  private base64data: string = "";
  private field: any = this.queryColumn;

  public mounted() {
    const x: {
      name: string | null;
      data: string | null;
      type: string | null;
    } = this.getInitValue();
    if (x?.data) {
      // bei pdf nicht laden!
      if (x.type !== "application/pdf") {
        this.base64data = x.data;
      }
    }
    if (x?.name) {
      this.imageName = x.name;
      if (!this.base64data) {
        this.loadImage(x);
      }
    }
  }
  private loadImage(image: any) {
    if (image.datentyp === "Doku") {
      this.base64data = this.$api.fullUrl(
        "pdf2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
    } else {
      this.base64data = this.$api.fullUrl(
        "image2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
    }
  }
  private async fileChanged(file: File) {
    if (file !== undefined) {
      this.imageName = file.name;
      if (this.imageName.lastIndexOf(".") <= 0) {
        return;
      }
      try {
        const me = this;
        let thumbnail;
        if (Constants.isMobile && Capacitor.getPlatform() !== "android") {
          thumbnail = await resizeImage(file, 1100, 1100);
          // wenn IOS dann nicht so stark komprimieren!
        } else {
          thumbnail = await resizeImage(file, 1000, 1000);
        }
        const base64 = await createBase64Image(thumbnail);
        this.base64data = base64;
        me.valueChanged({ data: base64, name: file.name });
      } catch (error) {
        this.loading = false;
        console.log("error reading File:" + error);
        alert("Kann File leider nicht lesen!");
        this.imageFile = null;
      }
    }
  }
  // private createBase64Image(fileObject: any) {
  //   return new Promise<string>((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       this.loading = true;
  //       if (e.target) {
  //         resolve(e.target.result as string);
  //         this.loading = false;
  //       }
  //     };
  //     reader.readAsDataURL(fileObject);
  //   });
  // }
  public validate() {
    if (this.required) {
      if (this.imageName) {
        this.errorText = "";
        return true;
      }
      this.errorText = this.requiredMessage;
      return false;
    }

    return true;
  }
}
