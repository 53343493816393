










import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { getKZValueDisplay } from "@/DataHelper";

@Component({
  provide: {},
})
export default class StammPerson extends Vue {
  @Prop({ default: null }) public siteData: any;
}
