







































import {
  getKZColumn,
  getKZValue,
  getValue,
  getEinheitFromColumn,
  getKZTitle,
} from "@/DataHelper";
import { AxiosResponse } from "axios";
import { Vue, Component, Prop, Provide, Emit } from "vue-property-decorator";

declare interface ChartListItems extends ListItems {
  destination: number;
  value: number;
  chartColumns: any;
  chartData: any;
}

@Component
export default class BaseChartList extends Vue {
  @Prop() public aic?: number;
  @Prop({ default: false }) protected showClose!: boolean;
  @Prop({ default: false }) protected showFilter!: boolean;
  @Prop({ default: false }) protected showSpeedDial!: boolean;
  @Prop({ default: false }) protected showAvatarLeft!: boolean;
  @Prop({ default: false }) protected showAvatart!: boolean;
  @Prop({ default: false }) protected showHeader!: boolean;

  @Prop({ default: false }) protected print!: boolean;
  @Prop() public zeitbereich!: APIZeitbereich | null;
  @Prop() public editZeitbereich!: APIZeitbereich | null;
  @Prop() public query!: string | undefined;
  @Prop() public queryEdit!: string | undefined;
  @Prop({ default: null }) public siteData: any;
  @Prop() protected fixHeight!: number;
  @Prop() protected druckItems!: PrintItems[];
  @Prop() protected exportItems!: AbfrageItems[];
  @Prop({ default: false }) public useVecStamm!: boolean;
  @Prop({ default: false }) private persoenlich!: boolean;

  @Prop({ default: null }) public formular!: ALLFrame;

  protected vecStamm: any[] = [];
  private queryChart!: string | undefined;
  private chartDataLoading: boolean = false;
  private chartDataLoading1: boolean = false;
  private chartType!: string | undefined;

  public chartData?: QueryResponse;
  public chartColumns: any[] = [];
  private unit: string = "";
  private textValue!: string | undefined;
  private textDest: string | undefined = "";
  private textDiff: string | undefined = "";
  private sendBack(param: any) {
    this.$emit("back", param);
  }
  private sendVector(param: any) {
    this.$emit("vector", param);
  }
  private diagramCol?: DataColumn;
  private diagramCol1?: DataColumn;
  protected baseListDataLoaded(data: QueryResponse) {
    this.diagramCol = data.columns?.find((col) => col.meta.kz === "diagramm");
    if (this.diagramCol) {
      this.chartDataLoading = true;
    }
    if (this.diagramCol && this.diagramCol.meta) {
      if (this.diagramCol.meta.subAbf) {
        const metasubAbf = this.diagramCol.meta.subAbf[0];
        this.queryChart = metasubAbf.kennung ?? "";
      }
      if (this.diagramCol.meta.subGra) {
        const metasubGra = this.diagramCol.meta.subGra[0];
        this.chartType = metasubGra.kennung ?? "";
      }
    }
    if (this.chartType) {
      if (this.chartType === "Web_Pie") {
        this.chartType = "Pie";
      }
    }
    let setztVector = "";
    if (this.useVecStamm) {
      setztVector = "1";
    }
    if (!this.queryChart) {
      this.chartDataLoading = false;
    } else {
      let formAic = 0;
      if (this.formular) {
        formAic = this.formular.aic;
      }
      const me = this;
      this.$api.getQuery(
        this.queryChart,
        (d: any) => me.chartDataLoaded(d, false),
        null,
        this.editZeitbereich,
        0,
        false,
        false,
        setztVector,
        formAic,
        this.vecStamm
      );
    }
  }

  private aicCol?: DataColumn;
  protected chartDataLoaded(data: AxiosResponse<QueryResponse>, diagramm1: boolean) {
    this.chartData = data.data;
    this.chartColumns = this.chartData?.columns;
    if (diagramm1) {
      this.chartDataLoading1 = false;
    } else {
      this.chartDataLoading = false;
    }
    this.aicCol = getKZColumn("aic", this.chartData?.columns);
  }

  private addChartData(item: ChartListItems) {
    if (!this.aicCol) {
      return "--";
    }
    const row = this.chartData?.data.find((d: any) => {
      const dval = d[this.aicCol?.name ?? ""];
      return item.aic === dval?.aic;
    });
    if (row) {
      if (!this.chartType || this.chartType === "StackedBar") {
        item.value = getKZValue(row, "value", this.chartData?.columns);
        this.textValue = getKZTitle(row, "textValue", this.chartData?.columns);
        item.destination = getKZValue(row, "dest", this.chartData?.columns);
        this.textDest = getKZTitle(row, "textDest", this.chartData?.columns);
        this.textDiff = getKZTitle(row, "textDiff", this.chartData?.columns);
        const col = getKZColumn("dest", this.chartData?.columns);
        this.unit = getEinheitFromColumn(col).bezeichnung;
      } else {
        item.chartData = this.chartData;
        item.chartColumns = this.chartColumns;
      }
    }
  }
  protected checkDetailFormColumn(col: DataColumn): boolean {
    // überspringen für die diagramcolumn
    if (col === this.diagramCol) {
      return true;
    }
    return false;
  }
}
