




































































































































































































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { showSnackbar, showDialog } from "@/UIHelper";
// import Constants from "@/Constants";
import { Globals } from "@/Globals";
import { AxiosResponse } from "axios";
import { getMonthNamesShort } from "@/editor/CalenderHelper";
import {
  getKZValue,
  getKZValueDisplay,
  getKennungValueDisplay,
  getKennungValue,
  getKZTitle,
} from "@/DataHelper";

@Component
export default class Calendar extends Vue {
  private zeitbereich?: APIZeitbereich;
  constructor() {
    super();
  }
  private events: Event[] = [];
  private type!: "year" | "month" | "week" | "day";
  // private type!: "year" | "month" | "week" | "day" | "4day" | "category";
  private typeToLabel = {
    "year": "Jahr",
    "month": "Monat",
    "week": "Woche",
    "day": "Tag",
    "4day": "4 Tage",
  };

  private calenderStartDate?: string;
  private start: Start | null = null;
  private end: End | null = null;
  private today: string = new Date().toISOString().substr(0, 10);
  private focus: string = new Date().toISOString().substr(0, 10);
  private title: string = "";
  private selectedEvent = {};
  private selectedElement = null;
  private selectedOpen: boolean = false;
  private types: string[] = ["year", "month", "week", "day", "4day"];
  private mode: string = "column";
  private eventHeight: number = 20;
  // private modes: string[] = ["stack", "column"];
  // private value = "";
  private weekday = [1, 2, 3, 4, 5, 6, 0];
  private color: string = "#1976D2";
  private showWeek: boolean = true;
  private showFullWidth: boolean = false;
  private colors: string[] = [
    "blue",
    "indigo",
    "deep-purple",
    "cyan",
    "green",
    "orange",
    "grey darken-1",
  ];
  private dragTime: any;
  private dragEvent: any;
  private dragStart = null;
  private createEvent: any;
  private createStart = null;
  private extendOriginal = null;
  private width: string = "100%";

  @Prop() public query!: string | undefined;
  @Prop({ default: null }) public formular!: any;
  @Prop({ default: null }) public druckItems!: any;
  @Prop({ default: false }) public readOnly!: boolean;
  @Prop() public editorComponent!: string;
  @Prop({ default: true }) public allowDragAndDrop!: boolean;
  @Prop() public queryMitarbeiter!: string | undefined;
  @Prop({ default: "month" }) public takeType!: any;
  private refillRunning: boolean = false;
  private showLoading: boolean = false;
  private showSchedule: boolean = false;
  private queryAIC!: number | null;
  private initialized: boolean = false;
  private header: any = { bezeichnung: " -- " };
  private rowdata: any[] = [];
  private queryData: QueryResponse | null = null;
  private filterData: QueryResponse | null = null;
  private readOnlyMutable: boolean = false;
  private allColumns: DataColumn[] = [];
  private printItems: PrintItems[] = [];
  private printItemsVector: PrintItems[] = [];
  public created() {
    this.type = this.takeType;
    if (this.$isPhone()) {
      this.showWeek = false;
      this.showFullWidth = true;
    }
  }
  public mounted() {
    const heute = new Date();
    this.calenderStartDate = this.$api.zeitbereich.von
      .toISOStringWithTZ()
      .substring(0, 10);
    this.focus = new Date(heute.firstOfWeek()).toISOString().substr(0, 10);
    // warum findet er den calendar nicht? ist als ref definiert...
    if (this.type === "week") {
      // calendar.scrollToTime("08:00");
      this.calenderStartDate = this.focus;
    }
    if (!this.zeitbereich) {
      this.zeitbereich = this.$api.zeitbereich;
    }
    if (this.formular && this.formular.druck && this.formular.druck.lenghth > 0) {
      this.printItems = this.formular.druck.filter(
        (c: any) => c.stt === this.formular.stt
      );
      this.printItemsVector = this.formular.druck.filter(
        (c: any) => c.stt !== this.formular.stt
      );
    }
    this.reloadData();
  }
  protected reloadData() {
    if (this.refillRunning) {
      return;
    }
    this.refillRunning = true;
    try {
      console.log("reloadData()");
      if (this.query == null || this.query === "") {
        showSnackbar(
          this.$globalsBezeichnung(
            Globals.Begriff,
            "Web_keine_Abfrage",
            "keine Abfrage gefunden"
          )
        );
        this.showLoading = false;
        return;
      }
      this.showSchedule = false;
      const me = this;
      me.showLoading = true;

      const zeitbereich: APIZeitbereich = this.getZeitbereichFromView();

      const vecStamm: any[] = [];
      this.$api.getQuery(
        this.query,
        (response: any) => me.dataLoaded(response),
        this.queryAIC,
        zeitbereich,
        0,
        false,
        false,
        "0",
        this.formular.aic,
        vecStamm
      );
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  private getZeitbereichFromView() {
    const von = new Date(this.calenderStartDate ?? this.$api.zeitbereich.von);
    let bis = von;
    let bereich: string = "Tag";
    switch (this.type) {
      case "year":
        bis = von.addMonths(12, true);
        bereich = "Jahr";
        break;
      // case "category":
      case "month":
        bis = von.addMonths(1, true);
        bereich = "Monat";
        break;
      case "day":
        bis = von.addDays(1);
        bereich = "Tag";
        break;
      // case "4day":
      //   bis = von.addDays(4);
      //   bereich = "Woche";
      //   break;
      case "week":
        bis = von.addDays(7);
        bereich = "Woche";
        break;
    }

    const zeitbereich: APIZeitbereich = { von, bis, bereich };
    return zeitbereich;
  }

  private dataLoaded(response: AxiosResponse<QueryResponse>) {
    const calendar: any = this.$refs.calendar;
    this.showSchedule = true;
    this.queryData = response.data;
    this.getEvents(response);
  }
  private filteredDataChanged(filteredData: QueryResponse) {
    // this.showSchedule = false;
    // this.showLoading = true;
    // const calendar: any = this.$refs.calendar;
    // this.showSchedule = true;
    this.getEventsFiltered(filteredData);
  }
  private getEvents(response: AxiosResponse<QueryResponse>) {
    try {
      if (response?.data.error !== undefined) {
        this.$api.onfail(response.data.error);
        console.log(response.data.error);
        this.$emit("back");
        alert("ACHTUNG: " + response.data.error);
        return;
      }

      this.header = response.data.header;
      if (this.header.edit === false) {
        this.readOnlyMutable = true;
      }
      // this.queryData = response.data;
      this.rowdata = response.data.data;
      this.allColumns = response.data.columns;
      this.fillEvents();
    } finally {
      this.showLoading = false;
      this.refillRunning = false;
      this.initialized = true;
      const init = true;
      this.$emit("loaded", init);
    }
  }
  private getEventsFiltered(response: QueryResponse) {
    try {
      this.rowdata = response.data;
      this.allColumns = response.columns;
      this.fillEvents();
    } finally {
      this.showLoading = false;
      this.refillRunning = false;
      this.initialized = true;
      const init = true;
      this.$emit("loaded", init);
    }
  }
  private fillEvents() {
    const events = [];
    if (this.rowdata.length > 0) {
      for (let i = 0; i < this.rowdata.length; i++) {
        try {
          const event = this.rowdata[i];
          const id = getKennungValue(event, "SYSAIC", this.allColumns);
          let name = getKZValueDisplay(event, "subject", this.allColumns);
          const nameAic = getKZValue(event, "subject", this.allColumns, true);
          let name1 = getKZValueDisplay(event, "subject1", this.allColumns);
          let zeitartAnzeige = false;
          let timed = true; // allDay = false
          if (!name1) {
            name1 = getKZValueDisplay(event, "zeitart", this.allColumns);
            timed = false;
            if (name1) {
              zeitartAnzeige = true;
            }
          }
          let tag = getKennungValue(event, "VALIDITY", this.allColumns);
          if (tag) {
            tag = new Date(tag);
          }
          let tagesAnzeige = getKennungValueDisplay(event, "VALIDITY", this.allColumns);
          if (!tagesAnzeige) {
            tagesAnzeige = name;
          }
          let start = getKZValue(event, "von", this.allColumns);
          start = new Date(start);
          if (!tag) {
            tag = start;
          }
          let end = getKZValue(event, "bis", this.allColumns);

          if (zeitartAnzeige && end) {
            end = end.addDays(-1); // bei den Reisen darf ich keinen Tag abziehen...
          }
          let color = "green";
          let colorAbw = false;
          color = getKZValue(event, "color", this.allColumns);
          if (!color) {
            color = getKZValue(event, "colorTGM", this.allColumns);
            colorAbw = true;
          }
          if (!color) {
            color = getKZValue(event, "colorZeitart", this.allColumns);
            colorAbw = true;
          }
          if (!color) {
            color = "#bbdc00";
          }
          if (!end) {
            color = "red";
            end = new Date();
          }
          end = new Date(end);
          const aic = this.$api.user.aic;
          if (nameAic === aic) {
            color = "grey";
          }
          const IsBlock = getKZValue(event, "isBlock", this.allColumns);
          if (IsBlock) {
            name1 = getKZTitle(event, "isBlock", this.allColumns);
            color = "grey";
          }
          let moreInformation = getKZValueDisplay(event, "memo", this.allColumns);
          const titleInfo = getKZTitle(event, "memo", this.allColumns);
          if (moreInformation) {
            this.eventHeight = 40;
            moreInformation = titleInfo + ": " + moreInformation;
          }
          const von1 = getKZValueDisplay(event, "vonbis", this.allColumns);
          // const zuBewilligen = getKZValue(event, "zuBewilligen", this.allColumns);
          // Antrag = bewilligt - und damit gesperrt
          const bewilligt = getKZValue(event, "Antrag", this.allColumns);
          const bewilligtMA = getKZValue(event, "AntragSV", this.allColumns);

          const status = getKZValueDisplay(event, "status", this.allColumns);
          let htmText = "";
          if (status && !colorAbw) {
            name = status + "/ " + name;
          } else if (bewilligt && !colorAbw) {
            htmText = "&#10003";
          } else if (!bewilligt && !colorAbw) {
            htmText = "&#9632";
            color = "#edc266";
          }
          let htmText1 = "";
          if (bewilligtMA && !colorAbw) {
            htmText1 = "&#10003";
          } else if (!bewilligtMA && !colorAbw) {
            htmText1 = "&#9632";
          }
          if (htmText) {
            name = htmText1 + "/" + htmText + " " + name;
          }

          events.push({
            name,
            name1,
            color,
            start,
            end,
            von1,
            timed,
            moreInformation,
            tag,
            id,
            tagesAnzeige,
          });
          if (timed) {
            let start1 = getKennungValue(event, "von1", this.allColumns);
            let end1 = getKennungValue(event, "bis1", this.allColumns);
            if (start1 !== undefined) {
              start1 = new Date(start1);
              end1 = new Date(end1);
              const von2 = getKZValueDisplay(event, "vonbis1", this.allColumns);
              events.push({
                name,
                color,
                start: start1,
                end: end1,
                von1: von2,
                timed,
                moreInformation,
                tag,
                id,
                tagesAnzeige,
              });
            }
          }
          this.events = events;
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
  private day!: Date | null;
  private updateRange(start: any, end: any) {
    const events = [];
    const von = new Date(start.start.date);
    const bis = new Date(start.end.date);
    this.zeitbereich = {
      von,
      bis,
      bereich: "Tag",
    };
    this.start = start.start;
    this.end = start.end;
    if (this.start) {
      const yr = this.start.year;
      const mn = this.start.month;

      const mNames = [
        "Jänner",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "July",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];
      if (this.$isPhone()) {
        this.day = this.$api.zeitbereich.von;
        const monat = getMonthNamesShort()[this.day.getMonth()];
        const year = yr.toString().substr(-2);
        this.title = monat + ", " + year;
      } else {
        this.title = mNames[mn - 1] + ", " + yr;
      }
    }
  }
  private dialogBack(updatedata: any) {
    this.selectedOpen = false;
    if (updatedata) {
      this.reloadData();
    }
  }
  protected onDelete(aic: any, goBackOnSuccess: boolean = true) {
    const bewPool = aic;
    this.selectedOpen = false;
    if (!bewPool) {
      showSnackbar("keinen Datensatz übergeben!");
      return;
    }
    const aicAbfAllBez = this.$globalsKennung(Globals.Begriff, "WebPlanungSpeichern");
    if (!aicAbfAllBez) {
      showSnackbar({
        text: "Abfrage nicht gefunden",
        color: "error",
      });
      this.showLoading = false;
      return;
    }
    const aicAbfAll = aicAbfAllBez.aic;
    this.$api.DeleteData(
      this.header,
      bewPool,
      aicAbfAll,
      this.zeitbereich,
      (res: any) => {
        const msgType = res.data.msgType;
        let info = res.data.info;
        const memo = res.data.memo;
        const title = res.data.titel;
        if (memo) {
          info = memo;
        }

        if (info) {
          if (msgType === "Error") {
            showSnackbar({
              text: info,
              color: "error",
            });
          } else if (msgType === "Info") {
            showSnackbar({
              text: info,
              color: "info",
            });
          } else if (msgType === "Warnung") {
            showSnackbar({
              text: info,
              color: "warning",
            });
          }
          this.showLoading = false;
          return msgType;
        }

        showSnackbar(
          this.$globalsBegriffMemo(
            Globals.Begriff,
            "Datensatz_geloescht",
            "Daten wurden gelöscht"
          )
        ); // Geloescht
        if (goBackOnSuccess) {
          this.$emit("back", true);
        }
        this.reloadData();
      },
      (ex: any) => {
        if (ex && ex.response) {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
        }
        console.log(ex);
        if (ex !== this.$api.ApiBusyMessage) {
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Berechnung_lauft",
              "Berechnung läuft, bitte warten Sie diese ab."
            )
          );
        }
        this.reloadData();
      }
    );
    this.reloadData();
  }
  private setToday() {
    const heute = new Date();
    this.focus = new Date(heute.firstOfWeek()).toISOString().substr(0, 10);
    this.calenderStartDate = this.focus;
    this.refreshCalender();
  }
  private viewDay(date: any) {
    this.focus = date.date;
    this.type = "day";
    this.calenderStartDate = this.focus;
    this.refreshCalender();
  }
  private getEventColor(event: any) {
    return event.color;
  }
  private prev() {
    this.move(-1);
  }
  private next() {
    this.move(1);
  }
  private move(direction: 1 | -1) {
    const calendar: any = this.$refs.calendar;
    const startDate = new Date(this.calenderStartDate ?? "");
    switch (this.type) {
      case "day":
        this.calenderStartDate = new Date(calendar.start)
          .addDays(1 * direction)
          .toISOStringWithTZ()
          .substring(0, 10);
        break;
      case "week":
        this.calenderStartDate = new Date(calendar.start)
          .addDays(7 * direction)
          .toISOStringWithTZ()
          .substring(0, 10);
        break;
      case "month":
        this.calenderStartDate = new Date(calendar.start)
          .addMonths(1 * direction, false)
          .toISOStringWithTZ()
          .substring(0, 10);
        break;
      // case "4day":
      //   this.calenderStartDate = new Date(calendar.start)
      //     .addDays(4 * direction)
      //     .toISOStringWithTZ()
      //     .substring(0, 10);
      //   break;
    }
    this.refreshCalender();
  }

  private refreshCalender() {
    this.showSchedule = false;
    this.reloadData();
    this.$nextTick(() => (this.showSchedule = true));
    // let editor: BaseFilter | undefined;
    // const x = this.$refs.BaseFilter as Vue[];
    // if (x.length > 0) {
    //   editor = x[0] as BaseFilter;
    // }
    // editor?.refreshFilter();
    this.reloadData();
  }

  private showEvent(nativeEvent: any, event: Event) {
    const open = () => {
      // this.selectedEvent = event;
      this.selectedEvent = nativeEvent.event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
      // requestAnimationFrame(() =>
      //   requestAnimationFrame(() => (this.selectedOpen = true))
      // );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
      // requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }
  }
  private rnd(a: any, b: any) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }
  private startDrag(event: any) {
    if (event && event.timed) {
      this.dragEvent = event;
      this.dragTime = null;
      this.extendOriginal = null;
    }
  }
  private startTime(tms: any) {
    const mouse = this.toTime(tms);

    if (this.dragEvent && this.dragTime === null) {
      const start = this.dragEvent.start;

      this.dragTime = mouse - start;
    } else {
      this.createStart = this.roundTime(mouse);
      this.createEvent = {
        name: `Event #${this.events.length}`,
        color: this.rndElement(this.colors),
        start: this.createStart,
        end: this.createStart,
        timed: true,
      };

      this.events.push(this.createEvent);
    }
  }
  private toTime(tms: any) {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
  }
  private extendBottom(event: any) {
    this.createEvent = event;
    this.createStart = event.start;
    this.extendOriginal = event.end;
  }
  private mouseMove(tms: any) {
    const mouse = this.toTime(tms);

    if (this.dragEvent && this.dragTime !== null) {
      const start = this.dragEvent.start;
      const end = this.dragEvent.end;
      const duration = end - start;
      const newStartTime = mouse - this.dragTime;
      const newStart = this.roundTime(newStartTime);
      const newEnd = newStart + duration;

      this.dragEvent.start = newStart;
      this.dragEvent.end = newEnd;
    } else if (this.createEvent && this.createStart !== null) {
      const mouseRounded = this.roundTime(mouse, false);
      const min = Math.min(mouseRounded, this.createStart);
      const max = Math.max(mouseRounded, this.createStart);

      this.createEvent.start = min;
      this.createEvent.end = max;
    }
  }
  private endDrag() {
    this.dragTime = null;
    this.dragEvent = null;
    this.createEvent = null;
    this.createStart = null;
    this.extendOriginal = null;
  }
  private cancelDrag() {
    if (this.createEvent) {
      if (this.extendOriginal) {
        this.createEvent.end = this.extendOriginal;
      } else {
        const i = this.events.indexOf(this.createEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }
    }

    this.createEvent = null;
    this.createStart = null;
    this.dragTime = null;
    this.dragEvent = null;
  }
  private roundTime(time: any, down = true) {
    const roundTo = 15; // minutes
    const roundDownTime = roundTo * 60 * 1000;

    return down
      ? time - (time % roundDownTime)
      : time + (roundDownTime - (time % roundDownTime));
  }

  private rndElement(arr: any) {
    return arr[this.rnd(0, arr.length - 1)];
  }
  // private openDruckVorschau(item: any) {
  //   const me = this;
  //   const print = this.$globalsDruck("Begriff", false, "", "", item.aic);
  //   const printItem = print[0];
  //   let fullscreen = false;
  //   console.log("isPhone: " + this.$isPhone());
  //   if (Constants.isMobile && this.$isPhone()) {
  //     fullscreen = true;
  //   }
  //   const aic = me.queryAIC ?? this.$api.user.aic;
  //   showDialog({
  //     title: printItem.bezeichnung,
  //     site: "BaseDruck",
  //     fullscreen,
  //     width: 510,
  //     data: {
  //       zeitbereich: this.zeitbereich,
  //       printItem,
  //       aic,
  //       pers: printItem.pers,
  //       noSelect: true,
  //     },
  //     titleColor: "dvhBackground",
  //     titleClass: "white--text",
  //     onClose: () => {
  //       return true;
  //       /* nix tun*/
  //     },
  //   });
  // }
}
declare interface Start {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
declare interface End {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
declare interface Event {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
  categrory?: string[];
  tag: Date;
  tagesAnzeige: string;
}
