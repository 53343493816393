


































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class EditHotline extends EditPage {
  constructor() {
    super();
    this.query = "Web_DVH_Anlage_Hotline";
  }
  public disabled: boolean = false;
  public clearable: boolean = false;
  private deleteVisible: boolean = false;

  private initalize: boolean = false;

  public mounted() {
    debugger;
    this.showLoading = true;
    this.mountedBase();
  }
  protected initialize() {
    if (this.data && this.data.aic_Bew_pool) {
      this.deleteVisible = true;
    }
  }
}
