



































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getDisplay, getKZValueDisplay } from "@/DataHelper";
import CalcButton from "@/components/CalcButton.vue";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import { showSnackbar } from "@/UIHelper";

@Component
export default class VButtonCarousel extends Vue {
  @Prop() public query!: string | undefined;
  @Prop() protected aic!: number;
  @Prop() protected calculated!: any;
  @Prop({ default: "" }) protected variable!: string;
  @Prop({ default: "" }) protected variable1!: string;
  @Prop() protected modellDefault!: string;
  @Prop({ default: "" }) public varName!: any;
  @Prop({ default: "" }) public varName2!: any;
  @Prop({ default: "" }) public varDatum!: any;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public noCarousel!: boolean;
  @Prop({ default: false }) public showResult!: boolean;
  @Prop({ default: false }) public checkTimeZone!: boolean;
  @Prop({ default: false }) public checkGeoLocation!: boolean;
  @Prop() public modellAic!: number;
  @Prop() public icon!: string;
  private modellMounted: string = "PZE_Web_Zeitarten_buchen";
  private showCarousel: boolean = true;
  private showLoading: boolean = false;

  public zeitbereich!: APIZeitbereich | null;
  @Prop({ default: null }) public siteData: any;
  private carousel: boolean = false;
  private header: any = { bezeichnung: " -- " };
  private queryAIC!: number;
  private rowdata: any[] = [];
  private columns: any[] = [];
  private items: any[] = [];
  public mounted() {
    this.showLoading = true;
    if (this.modellDefault) {
      this.modellMounted = this.modellDefault;
    }
    if (this.modellAic) {
      this.queryAIC = this.modellAic;
    }
    console.log("Button Carousel list start...");
    try {
      if (this.noCarousel) {
        this.showCarousel = false;
        this.carousel = false;
      }
      if (Constants.isMobile) {
        this.showCarousel = false;
      }
      if (this.query == null || this.query === "") {
        showSnackbar(
          this.$globalsBezeichnung(
            Globals.Begriff,
            "Web_keine_Abfrage",
            "keine Abfrage gefunden"
          )
        );
        this.header = { bezeichnung: ";(" };
        return;
      }
      if (this.siteData) {
        if (this.siteData.zeitbereich) {
          this.zeitbereich = this.siteData.zeitbereich;
        }
      }
      const me = this;
      const vecStamm: any[] = [];
      this.$api.getQuery(
        // Query
        this.query,
        // Done function
        (data: any) => me.dataLoaded(data),
        this.queryAIC,
        this.zeitbereich,
        0,
        false,
        false,
        "",
        0,
        vecStamm
      );
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  private dataLoaded(data: any) {
    console.log("list data loaded");
    // Fehlerbehandlung
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }

    this.header = data.data.header.bezeichnung;
    this.columns = data.data.columns;
    this.rowdata = data.data.data;
    if (this.rowdata.length > 1) {
      this.carousel = true;
    }

    // this.count = data.data.data.header.zeilen;

    for (let i = 0; i < this.rowdata.length; i++) {
      const row = this.rowdata[i];
      const header = getKZValueDisplay(row, "header", this.columns);
      const titel = getKZValueDisplay(row, "titel", this.columns);
      const subtitel = getKZValueDisplay(row, "subtitel", this.columns);

      const text = getKZValueDisplay(row, "text", this.columns);
      // const icon = getKZValueDisplay(row, "icon", this.columns);
      const color = getKZValueDisplay(row, "color", this.columns);
      this.items.push({
        header,
        titel,
        subtitel,
        text,
        divider: true,
        inset: true,
        // icon,
        color,
      });
    }
    this.showLoading = false;
  }
  private onCalculating(args: any) {
    this.$emit("calculating", args); // einmal weiterreichen nach oben bitte..
  }

  private onCalculated(args: any) {
    this.$emit("calculated", args); // einmal weiterreichen nach oben bitte..
  }
}
