

































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { applyDrag, scene, lorem } from "../../KanbanHelpers";
import Kanban from "./kanban.vue";

@Component
export default class Board extends Vue {
  protected data() {
    return {
      scene,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
    };
  }
  private scene: any;

  private showDropdown: any;
  private item: any = {};
  private opcoesDropdownMenu: any[] = [
    { type: "customAction" },
    { type: "separator", color: "yellow" },
    { type: "customAction" },
  ];
  private columns: any[] = [
    {
      name: "offen",
      id: 0,
      status: ["open"],
      columnItems: [],
      validation: (src: any, payload: any, index: any, origins: any) =>
        this.columnValidation(src, payload, index, (origins = [""])),
    },
    {
      name: "gelöst",
      id: 1,
      status: ["executing", "review", "paralised"],
      columnItems: [],
      validation: (src: any, payload: any, index: any, origins: any) =>
        this.columnValidation(src, payload, index, (origins = ["open", "paralised"])),
    },
    {
      name: "abgeschlossen",
      id: 2,
      status: ["finished", "closed"],
      columnItems: [],
      validation: (src: any, payload: any, index: any, origins: any) =>
        this.columnValidation(src, payload, index, (origins = ["executing", "review"])),
    },
  ];
  private iconType(status: any) {
    switch (status) {
      case "finished":
        return "🚀";
      case "executing":
        return "🔥";
      default:
        return "🤠";
    }
  }
  private mounted() {
    this.cardsKanban(0, 3, "open", ["executing", "paralised"]);
    this.cardsKanban(1, 2, "executing", ["paralised", "review"]);
    this.cardsKanban(2, 0, "finished", ["closed", "finished"]);
  }
  private cardsKanban(colunaId: any, items: any, currentStatus: any, statusList: any) {
    const current = this.columns.find((item: any) => item.id === colunaId);
    const random = Math.floor(Math.random() * 99);
    for (let index = 0; index <= items; index++) {
      current.columnItems.push({
        id: random + index + 1,
        task: lorem.slice(0, Math.floor(Math.random() * 20) + 10),
        date: `11/2${index}/2020`,
        status: statusList,
        currentStatus,
        icon: this.iconType(currentStatus),
        dropdownId: index,
        corStatus: {
          nome: currentStatus,
          corTexto: `#2${index}1ba8`,
          cor: `#2${index}3ced`,
        },
      });
    }
  }
  private selectStatus(item: any) {
    this.showDropdown = {
      id: item.id,
    };
  }
  private columnChange(item: any, column: any) {
    setTimeout(() => {
      this.columns.reduce((total: any, el: any) => {
        const items = el.columnItems.map((i: any) => i.id);
        if (items.includes(item.id)) {
          const newStatus = el.columnItems.find((i: any) => i.id === item.id);
          if (Array.isArray(column) && column.length) {
            console.log(column);
            newStatus.icon = this.iconType(column[0]);
            return (newStatus.currentStatus = column[0]);
          }
          newStatus.currentStatus = column;
          newStatus.icon = this.iconType(column);
          const ab = el.columnItems.findIndex((i: any) => i.id === newStatus.id);
          const colunaDestino = this.columns.findIndex((col: any) =>
            col.status.includes(newStatus.currentStatus)
          );
          el.columnItems.splice(ab, 1);
          this.columns[colunaDestino].columnItems.push(newStatus);
          return newStatus;
        } else {
          return this.columns;
        }
      }, 0);
    });
  }
  private columnValidation(src: any, payload: any, index: any, possibleOrigins: any) {
    return possibleOrigins.includes(payload.currentStatus);
  }

  private onColumnDrop(dropResult: any) {
    const scene = Object.assign({}, this.scene);
    scene.children = applyDrag(scene.children, dropResult);
    this.scene = scene;
  }

  private onCardDrop(columnId: any, dropResult: any) {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const scene = Object.assign({}, this.scene);
      const column = scene.children.filter((p: any) => p.id === columnId)[0];
      const columnIndex = scene.children.indexOf(column);

      const newColumn = Object.assign({}, column);
      newColumn.children = applyDrag(newColumn.children, dropResult);
      scene.children.splice(columnIndex, 1, newColumn);

      this.scene = scene;
    }
  }

  private getCardPayload(columnId: any) {
    return (index: any) => {
      return this.scene.children.filter((p: any) => p.id === columnId)[0].children[index];
    };
  }

  private dragStart() {
    console.log("drag started");
  }
}
