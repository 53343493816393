









































































































































































































import {
  getDisplay,
  getDisplayValue,
  getKZValue,
  getValue,
  toNumber,
} from "@/DataHelper";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { SliderConfig } from "./BaseFilterSlider.vue";

@Component
export default class BaseFilter extends Vue {
  private filterColumns: FilteredDataColumn[] | null = null;
  private groupColumns: DataColumn[] | null = null;
  private groupColumn: DataColumn | null = null;

  @Prop({ default: () => ["titel", "header", "subtitel", "name", "subject"] })
  private textSearchColumnKZ!: string[];

  private menu: boolean = false;
  private filteredRows: any[] = [];
  public searchText: string = "";
  private emptyFilterText: string = "- leer -";
  private emptyGroupText: string = "- leer -";

  public color: string = "white";
  @Prop({ default: "white" }) public colorUbergabe!: string;
  @Prop({ default: false }) public showVorAustritt!: boolean;
  private currentVal: any = null;
  private enableFilter: boolean = true;
  private localName!: string;
  private selectVorAustritt: boolean = false;

  private jarVersion: boolean = false;

  @Prop() public readonly queryData!: QueryResponse;

  private datarows: any[] | undefined;
  @Watch("queryData")
  public onQueryDataChanged() {
    console.log("baseFilter querydata length: " + this.queryData?.data?.length);
  }
  private get columns() {
    return this.queryData?.columns;
  }
  private get header() {
    return this.queryData?.header;
  }
  private created() {
    this.localName = this.$api.user.bezeichnung + this.queryData.header.kennung;
    this.color = this.colorUbergabe;
  }
  public mounted() {
    // this.initAll = false;
    if (!this.queryData) {
      console.log("no query data!");
      return;
    }
    this.datarows = [...this.queryData.data];
    this.refreshFilter();
    this.updateFilteredRows();

    this.localName = this.$api.user.bezeichnung + this.queryData.header.kennung;
    const localParameter: string = this.enableFilter.toString();
    window.localStorage.setItem(this.localName, localParameter);
  }

  private searchTextColumns: DataColumn[] = [];
  private sliderConfig: SliderConfig[] = [];
  private getSlider(col: DataColumn) {
    return this.sliderConfig.find((s) => s.filterCol === col);
  }
  private initAll: boolean = false;

  public refreshFilter(reset?: boolean) {
    try {
      const jar = this.$api.user.AllVersion;
      this.jarVersion = !jar.includes("5.15"); // erst ab 5.16 können pers. Parameter gespeichert werdeb

      this.searchTextColumns = this.queryData.columns.filter(
        (c) => c.meta.kz && this.textSearchColumnKZ.indexOf(c.meta.kz) >= 0
      );
      const filterColumns: FilteredDataColumn[] = (this.filterColumns = this.columns.filter(
        (c: any) => c.meta.filterOK === true && !c.meta.invisible && !c.meta.banned
      ) as FilteredDataColumn[]);

      if (!filterColumns || filterColumns.length === 0) {
        console.log("no filtered columns found!");
        return;
      }
      const filterALL = this.columns.find((c: any) => c.meta.filterInit === true);
      if (filterALL) {
        this.initAll = true;
        this.enableFilter = false;
        if (localStorage.getItem(this.localName)) {
          const localFilter = localStorage.getItem(this.localName);
          let boolFilter = false;
          if (localFilter === "true") {
            boolFilter = true;
            this.color = "green";
          }
          this.enableFilter = boolFilter;
        }
      }
      this.sliderConfig = [];
      const data: any[] = this.datarows ? [...this.datarows] : [];
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        if (filterColumns.length > 0) {
          filterColumns.forEach((fc, ix) => {
            const isCheckbox = fc.type === "checkBox";
            const isCheckbox3 = fc.type === "checkBox3";
            if (reset) {
              fc.filterValues = [];
            }
            if (!fc.filterValues) {
              fc.filterValues = [];
            }
            const filterVal = getDisplay(row, fc) ?? this.emptyFilterText;
            const filterAic = getValue(row, fc, true);
            const setFilter = fc.meta.value;
            if (isCheckbox && fc.filterValues.length === 0) {
              fc.filterValues.push({
                checked: setFilter?.length === 1 && setFilter[0] === true,
                value: filterVal,
              });
              fc.filterSwitchValue = setFilter?.length === 1 && setFilter[0] === true;
              return;
            }
            if (fc.type === "slider") {
              let sl = this.getSlider(fc);
              const sliderVal = toNumber(filterAic);
              if (sliderVal) {
                if (!sl) {
                  sl = { filterCol: fc, min: sliderVal, max: sliderVal };
                  this.sliderConfig.push(sl);
                  return;
                }
                if (sl.min > sliderVal) {
                  sl.min = sliderVal;
                }
                if (sl.max < sliderVal) {
                  sl.max = sliderVal;
                }
                return;
              }
            }
            if (filterVal && filterVal !== "- leer -") {
              if (!fc.filterValues.find((f: any) => f.value === filterVal)) {
                let checked = true;
                if (setFilter && !isCheckbox3) {
                  const comboData = fc.meta.combo;
                  if (!isCheckbox3 && !isCheckbox && comboData) {
                    for (let j = 0; j < comboData.length; j++) {
                      const metaRow = comboData[j];
                      if (
                        !fc.filterValues.find((f: any) => f.value === metaRow.bezeichnung)
                      ) {
                        if (setFilter.indexOf(metaRow.aic) >= 0) {
                          checked = true;
                        } else {
                          checked = false;
                        }
                        fc.filterValues.push({
                          checked,
                          value: metaRow.bezeichnung,
                          aic: metaRow.aic,
                        });
                      }
                    }
                  } else if (setFilter.indexOf(filterAic) >= 0) {
                    checked = true;
                    if (isCheckbox && filterAic) {
                      fc.filterSwitchValue = isCheckbox; // setzen wenn true und checkbox
                    }
                  } else {
                    checked = false;
                  }
                  // fc.filterValues.push({
                  //   checked,
                  //   value: filterVal,
                  //   aic: filterAic,
                  // });
                } else if (isCheckbox3) {
                  if (this.initAll && setFilter) {
                    const checkALL = setFilter.find((f: any) => f === "initAll");
                    this.enableFilter = true;
                    if (localStorage.getItem(this.localName)) {
                      const localFilter = localStorage.getItem(this.localName);
                      let boolFilter = false;
                      if (localFilter === "true") {
                        boolFilter = true;
                      }
                      this.enableFilter = boolFilter;
                    } else {
                      const localParameter: string = this.enableFilter.toString();
                      window.localStorage.setItem(this.localName, localParameter);
                    }
                  }
                  const meta = fc.meta;
                  checked = true;
                  const combo = meta.combo;
                  if (combo && combo.length > 0) {
                    const text1 = combo[0].bezeichnung;
                    const text2 = combo[1].bezeichnung;
                    const text3 = combo[2].bezeichnung;

                    const value1 = combo[0].nr;
                    const value2 = combo[1].nr;
                    const value3 = combo[2].nr;

                    const aic1 = combo[0].aic;
                    const aic2 = combo[1].aic;
                    const aic3 = combo[2].aic;
                    if (setFilter) {
                      const check1 = setFilter.find((f: any) => f === aic1);
                      checked = check1 ? checked : false;
                      fc.filterValues.push({
                        checked,
                        value: text1,
                        aic: aic1,
                      });
                      checked = true;
                      const check2 = setFilter.find((f: any) => f === aic2);
                      checked = check2 ? checked : false;
                      fc.filterValues.push({
                        checked,
                        value: text2,
                        aic: aic2,
                      });
                      checked = true;
                      const check3 = setFilter.find((f: any) => f === aic3);
                      checked = check3 ? checked : false;
                      fc.filterValues.push({
                        checked,
                        value: text3,
                        aic: aic3,
                      });
                    } else {
                      fc.filterValues.push({
                        checked,
                        value: text1,
                        aic: aic1,
                      });
                      fc.filterValues.push({
                        checked,
                        value: text2,
                        aic: aic2,
                      });
                      fc.filterValues.push({
                        checked,
                        value: text3,
                        aic: aic3,
                      });
                    }
                  }
                } else {
                  const comboData = fc.meta.combo;
                  if (comboData) {
                    for (let j = 0; j < comboData.length; j++) {
                      const metaRow = comboData[j];
                      fc.filterValues.push({
                        checked,
                        value: metaRow.bezeichnung,
                        aic: metaRow.aic,
                      });
                    }
                  } else {
                    fc.filterValues.push({
                      checked,
                      value: filterVal,
                      aic: filterAic,
                    });
                  }
                }
              }
              fc.filterValues.sort(this.dynamicSort("value"));
            }
          });
        }
      }
    } catch (e) {
      console.error("error creating filter:" + e);
    }
  }
  private resetFilter() {
    if (this.filterColumns && this.filterColumns.length > 0) {
      if (!this.initAll) {
        this.filterColumns.forEach((fc: any, ix) => {
          if (fc.type === "checkBox") {
            fc.filterSwitchValue = false;
          }
          if ((fc.filterValues?.length ?? 0) > 0) {
            for (let j = 0; j < fc.filterValues.length; j++) {
              fc.filterValues[j].checked = true;
            }
          }
        });
      } else if (this.enableFilter) {
        this.filterColumns.forEach((fc: any, ix) => {
          if (fc.type === "checkBox") {
            fc.filterSwitchValue = false;
          }
          if ((fc.filterValues?.length ?? 0) > 0) {
            for (let j = 0; j < fc.filterValues.length; j++) {
              fc.filterValues[j].checked = true;
            }
          }
        });
      }
    }
    // sliders zurücksetzen...
    this.sliderConfig.forEach((sl) => (sl.range = [sl.min, sl.max]));
    this.color = this.colorUbergabe;
    this.searchText = "";
    this.refreshFilter();
    this.groupColumns = [];
    this.groupColumnChanged();
    this.updateFilteredRows();
    this.$emit("reloadData");
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  private setUserPara() {
    const varUbergabe: any[] = [];
    let value: any[] = [];
    const cols = this.filterColumns;
    if (cols && cols.length > 0) {
      for (let i = 0; i < cols.length; i++) {
        // if (!cols[i].filterSwitchValue) {
        // das hab ich dazu getan - weil bei false - immer undefinded drinnen steht...
        //   cols[i].filterSwitchValue = false;
        // }
        if (cols[i].type === "checkBox") {
          varUbergabe.push({
            aic: cols[i].aic,
            value: [cols[i].filterSwitchValue ?? false],
          });
          continue;
        }
        const filterItems = cols[i].filterValues;
        value = [];
        const valueList = filterItems.filter((c: any) => c.checked === true);
        for (let j = 0; j < valueList.length; j++) {
          if (valueList[j].aic || valueList[j].aic === 0) {
            value.push(valueList[j].aic);
          }
        }
        if (this.initAll && cols[i].type === "checkBox3") {
          value.push("initAll");
        }
        varUbergabe.push({
          aic: cols[i].aic,
          value,
        });
      }
    }
    if (this.groupColumns) {
      if (this.groupColumn) {
        varUbergabe.push({
          aic: this.groupColumn.aic,
          pGruppiert: true,
        });
      } else if (this.groupColumns && this.groupColumns.length > 0) {
        varUbergabe.push({
          aic: this.groupColumns[0].aic,
          pGruppiert: false,
        });
      }
    }
    this.color = "green";
    this.$api.setPersParameter(varUbergabe);
  }
  private deleteUserPara() {
    const filter = this.enableFilter;
    const aicBegriff = this.queryData.header.aicBegriff;
    this.$api.deletePersParameter(aicBegriff).then((x: any) => this.resetFilter());
  }
  private resetSavedFilter() {
    this.searchText = "";
    this.filterColumns = [];
    this.filteredRows = [];
    this.groupColumns = [];
    this.groupColumn = null;
    window.localStorage.setItem(this.localName, "");
    this.refreshFilter(true);
    this.updateFilteredRows();
    this.$emit("reloadData");
  }

  private checkChanged(fv: any) {
    fv.checked = !fv.checked;
    this.$forceUpdate();
    this.$emit("filterChanged");
    this.updateFilteredRows();
  }

  public getSearchText() {
    return this.searchText;
  }

  protected updateFilteredRows() {
    this.color = this.colorUbergabe;
    if (
      (!this.enableFilter && !this.searchText) ||
      (!this.filterColumns && !this.searchText)
    ) {
      if (this.datarows) {
        this.filteredRows = this.datarows ? [...this.datarows] : [];
      } else {
        this.filteredRows = [];
      }

      const eventdata1: FilteredQueryResponse = {
        filterActive: false,
        header: this.queryData.header,
        data: [...this.filteredRows],
        columns: this.queryData.columns,
        error: this.queryData.error,
        searchText: this.searchText,
      };
      this.$emit("filteredDataChanged", eventdata1);
      return;
    }
    const me = this;
    // if (me.searchText) {
    //   this.datarows = [...this.queryData.data];
    // }
    const data = [...this.datarows!];
    this.filteredRows = [];
    if (data) {
      this.filteredRows = data.filter((row) => {
        let match = true;
        if (me.searchText) {
          this.color = "green";
          const search = me.searchText.toLowerCase();
          const inText = false;

          const containingColumn = this.searchTextColumns.find((c) => {
            let val = getDisplay(row, c);
            if (val) {
              val = val.toLowerCase();
              if (val.indexOf(search) >= 0) {
                return true;
              }
            }
            return false;
          });

          if (containingColumn) {
            match = true;
          } else {
            match = false;
          }
        }

        if (!me.filterColumns) {
          return true;
        }
        const col: any = me.filterColumns;
        const colValue = col.filterValues;
        me.filterColumns.forEach((fc) => {
          const fv = getDisplay(row, fc) ?? this.emptyFilterText;
          const rowValue = getValue(row, fc);
          if (fc.type === "checkBox") {
            if (fc.filterSwitchValue) {
              this.color = "green";
              // wenn nicht dann alle
              const jaVal = getDisplayValue(true, fc);
              match = match && jaVal === fv;
            }
          } else if (fc.type === "slider") {
            const sl = this.getSlider(fc);
            if (!sl?.range) {
              return;
            }
            const min = sl.range[0] ?? sl.min;
            const max = sl.range[1] ?? sl.max;

            match = match && rowValue >= min && rowValue <= max;
            return;
            // }
            // else if (fc.type === "checkBox3" && this.initAll) {
            //   fc.filterValues.forEach((fx, ix) => {
            //     if (!fc.filterValues.find((f: any) => f.aic === f.meta.value)) {
            //       fc.filterValues.checked = false;
            //     }
            //   });
            //   // const check = filterValues.find((f: any) => f === fc.filterValues[i].aic);
          } else {
            const checkFilter = fc.filterValues.find((ffv: any) => !ffv.checked);
            if (checkFilter) {
              this.color = "green";
            } else if (this.initAll && this.enableFilter) {
              this.color = "green";
            }
            if (fv === "- leer -") {
              // wenn keine Daten da sind dann trotzdem anzeigen!
              // match = true;  // einfach nix machn weil die andren suchkriterien gelten ja trotzdem.
            } else {
              match =
                match &&
                fc.filterValues.find((ffv: any) => ffv.value === fv && ffv.checked) !=
                  null;
            }
          }
        });
        return match;
      });
    }
    const filteredRows = this.filteredRows.sort((a, b) => {
      if (a.group > b.group) {
        return 1;
      } else if (b.group > a.group) {
        return -1;
      }
      return 0;
    });
    // const col: any = me.filterColumns;
    // const colValue = col.filterValues;
    let filterActive = true;
    // wurde für den Filter nix gefunden - dann auch nicht auf ACTIVE setzen!
    const filter2 = this.columns.find((c: any) => c.meta.filter2 === true);
    // parameter alternativer Filter bei der Spalte
    // dann sollen nicht nur MA mit Daten sondern ALLE angzeigt werden (Dienstoplan)
    if (filter2) {
      filterActive = false;
    }
    // info ob ein Filter eingeschalten wurde
    let filterSet = false;
    if (this.color === "green") {
      filterSet = true;
    }
    const eventdata: FilteredQueryResponse = {
      filterActive,
      header: this.queryData.header,
      data: [...this.filteredRows],
      columns: this.queryData.columns,
      error: this.queryData.error,
      searchText: this.searchText,
      filterSet,
    };
    this.$emit("filteredDataChanged", eventdata);
  }

  private groupColumnChanged(gv?: DataColumn) {
    if (gv === this.groupColumn) {
      gv = undefined;
    }
    this.groupColumn = gv ?? null;
    this.$emit("groupColumnChanged", gv);
    this.$emit("reloadData");
  }

  private setBooleanFilterValue(fc: FilteredDataColumn, value: boolean) {
    fc.filterSwitchValue = value;
    if (value) {
      const jaVal = getDisplayValue(true, fc);
      fc.filterValues.forEach((fv) => (fv.checked = fv.value === jaVal));
    } else {
      fc.filterValues.forEach((fv) => (fv.checked = true));
    }
    this.updateFilteredRows();
  }

  private countBooleanFilterableValues(fc: FilteredDataColumn) {
    let rows = this.datarows;
    if (this.filteredRows && this.filteredRows.length > 0) {
      rows = this.filteredRows;
    }
    const setItemCount = rows?.filter((item) => getValue(item, fc) === true).length;

    return (setItemCount ?? "??") + "/" + (this.datarows?.length ?? "??");
  }

  protected filterChanged() {
    this.updateFilteredRows();
  }
  private setdefaultFilter() {
    const localParameter: string = this.enableFilter.toString();

    window.localStorage.setItem(this.localName, localParameter);
    if (this.enableFilter) {
      this.$emit("filterChanged");
      this.updateFilteredRows();
    } else {
      this.$emit("filterChanged");
      this.resetFilter();
    }
  }
  private setIsWeg() {
    this.$emit("setIsWeg", this.selectVorAustritt);
  }
}
