


















































































































































































































































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { getKZValueDisplay } from "@/DataHelper";
import { onShowSite } from "@/NavigationHelper";

@Component({
  provide: {},
})
export default class StammMaske extends EditPage {
  @Prop() protected stammQuery!: string;
  @Prop() protected auswahlQuery!: string;
  @Prop() protected neuDialog!: string;
  @Prop() protected showUser: boolean = false;
  public dialogOpen: boolean = false;
  private myHeight: number = window.innerHeight;
  private showDialog: boolean = false;
  private initalized: boolean = false;
  private showBenutzer: boolean = false;
  public mini: boolean = true;
  public drawer: boolean = true;
  public wasopen = false;
  public showData: boolean = false;
  public selecteditem: number = 0;
  public edit: boolean = true;
  public showZeitraumInHeader: boolean = true;
  public stammBezeichnung: string = "";
  public saveVisibleStt: boolean = false;
  public dialogDelete: boolean = false;
  private avatar: string = "";

  public dialogStt: any = {
    aic: 0,
    title: "--!!--",
    componentName: "None",
    zeitbereich: null,
    showCustomEditor: false,
    showCustomGrid: false,
    query: "",
    lastValues: null,
    newData: false,

    width: 600,
    height: 200,
    fullscreen: false,
  };
  protected created() {
    this.query = this.stammQuery;
    this.bewegungsdaten = false;
    this.showLoading = true;
    this.backOnSave = false;
  }
  public mounted() {
    if (this.siteData && this.siteData?.aic !== 0) {
      this.selected = this.siteData.aic;
      this.siteData.aic = 0;
    }
    this.dialogStt.query = this.neuDialog;
    this.selecteditem = 0;
  }
  public initialize() {
    if (this.showUser) {
      const avatarData = getKZValueDisplay(this.data, "avatar", this.columns);
      if (avatarData) {
        if (avatarData.data) {
          this.avatar = avatarData.data;
        } else {
          this.loadImage(avatarData);
          this.avatar = this.avatarIcon;
        }
      }
    }
    this.stammBezeichnung = getKZValueDisplay(this.data, "name", this.columns);
    this.stammBezeichnung =
      this.stammBezeichnung + " " + getKZValueDisplay(this.data, "name2", this.columns);
    if (this.stammKennung === "" || !this.stammKennung) {
      this.stammKennung = this.stammBezeichnung.replace(/\s+/g, "");
    }
    this.initalized = true;
  }
  private async deleteSttMain(selected: number) {
    this.onDeleteAsync(selected).then(() => {
      this.$nextTick(() => this.reloadList());
    });
  }
  private reloadList() {
    this.dialogDelete = false;
    this.$forceUpdate();
    const comp = this.$refs.comboList as any;
    if (comp.refreshData) {
      comp.refreshData();
      setTimeout(() => this.mountedBase(), 100);
    }
  }
  @Prop({ default: null }) protected onClosed!: () => void | null;
  public newItem(item: any) {
    this.dialogStt.aic = undefined;
    this.dialogStt.newData = true;
    this.dialogOpen = true;
  }
  public editItem(edit: any) {
    this.edit = edit;
  }
  private onBack(updatedata: any) {
    if (updatedata) {
      this.$forceUpdate();
      const comp = this.$refs.comboList as any;
      if (comp.refreshData) {
        comp.refreshData().then((loadedData: QueryResponse) => {
          comp.selectByAic(updatedata[0].aic_Stamm);
        });
      }
      this.$nextTick(() => {
        setTimeout(() => this.mountedBase(), 100);
      });
    }
    this.dialogOpen = false;
  }
  public changedMe(val: boolean) {
    // wenn grad geschlossen app informieren
    if (this.drawer) {
      this.wasopen = true;
    } else if (!this.drawer && this.onClosed && this.wasopen) {
      this.onClosed();
      this.wasopen = false;
    }
  }
  public onClose() {
    this.siteData.reload = true;
    this.$emit("back");
  }
  public valueChangedSelect(x: any) {
    if (this.saveFrage) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
      } else {
        return;
      }
    }
    this.edit = true;
    this.showData = true;
    this.queryAIC = x.aic;
    this.selected = x.aic;
    this.aic = x.aic;
    if (this.siteData && this.siteData?.aic !== 0) {
      this.siteData.aic = 0;
    }
    this.mountedBase();
  }
  public onShowSiteLocal(site: string, name: string) {
    if (this.saveFrage || this.saveVisibleStt) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
        onShowSite({ site, title: name });
      }
    } else {
      onShowSite({ site, title: name });
    }
  }
  private checkSite(item: any) {
    if (this.saveFrage || this.saveVisibleStt) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
        this.step = item.step;
        this.mini = !this.mini;
      }
    } else {
      this.step = item.step;
      this.mini = !this.mini;
    }
  }
  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  @Watch("drawer")
  private drawerWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.drawer = val;
    }
  }
  @Watch("mini")
  private miniWatch(val: boolean, oldval: boolean) {
    if (oldval !== val) {
      this.mini = val;
    }
  }
}
