import { Vue } from "vue-property-decorator";
import { RenderDayCellEventArgs } from "@syncfusion/ej2-calendars";
import AUApi from "@/api";

export function getMask(format: string) {
  let formatted = format;
  formatted = formatted.replace(/w/, "11");

  formatted = formatted.replace(/yyyy/, "1111");
  formatted = formatted.replace(/yy/, "11");
  formatted = formatted.replace(/MM/, "M1");
  formatted = formatted.replace(/dd/, "D1");

  formatted = formatted.replace(/HH/, "H1");
  formatted = formatted.replace(/mm/, "51");
  formatted = formatted.replace(/ss/, "51");

  formatted = formatted.replace(/EE/, "");

  return formatted.trim();
}

export function valueToFormatStr(value: Date, format: string) {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  let formatted = format + "";

  const days = getWeekdayNamesShort();

  const weekday = days[date.getDay()];
  const week = date.getWeekNumber();
  const year = date.getFullYear().toString();
  const year2digit = date
    .getFullYear()
    .toString()
    .substr(-2);
  const month2Digit = ("00" + (date.getMonth() + 1).toString()).substr(-2);
  const day2Digit = ("00" + date.getDate().toString()).substr(-2);
  const hour24 = ("00" + date.getHours()).substr(-2);
  const min = ("00" + date.getMinutes()).substr(-2);
  const sec = ("00" + date.getSeconds()).substr(-2);

  formatted = formatted.replace(/w/, week.toString());
  formatted = formatted.replace(/ww/, week.toString());

  formatted = formatted.replace(/yyyy/, year);
  formatted = formatted.replace(/yy/, year2digit);
  formatted = formatted.replace(/MM/, month2Digit);
  formatted = formatted.replace(/dd/, day2Digit);

  formatted = formatted.replace(/HH/, hour24);
  formatted = formatted.replace(/mm/, min);
  formatted = formatted.replace(/ss/, sec);

  formatted = formatted.replace(/EE/, weekday);

  return formatted;
}

export function getDateFromInput(
  input: string,
  format: string
): DateParseReponse {
  try {
    if (format.indexOf("EE") >= 0) {
      format = format.replace(/EE/, "").trim();
    }
    const out = format;

    const fn = (pattern: string, value: string) => {
      const ix = format.indexOf(pattern);
      if (ix >= 0) {
        const x = parseInt(value.substr(ix, pattern.length), 10);
        if (isNaN(x)) {
          return null;
        }
        return x;
      }
      return null;
    };

    let year = fn("yyyy", input);
    if (!year) {
      year = fn("yy", input);
      if (year) {
        year += 2000;
      }
    }
    if (year == null) {
      year = 1970;
    }

    let month = fn("MM", input); // Monat ist 0 basierend...
    if (month !== null) {
      month -= 1;
    }
    if (month === null) {
      month = 0;
    }

    let day = fn("dd", input);
    if (day === null) {
      day = 1;
    }

    let hour = fn("HH", input);
    if (hour === null) {
      hour = 0;
    }

    let min = fn("mm", input);
    if (min === null) {
      min = 0;
    }

    let error = "";
    if (min > 60 || min < 0) {
      error += txtMinute + ",";
    }
    if (hour > 23 || hour < 0) {
      error += txtStunde + ", ";
    }

    if (month > 11 || month < 0) {
      error += txtMonat + ", ";
    }
    if (year < 1970) {
      error += txtJahr + ",";
    }
    if (day <= 0 || day > Date.daysInMonth(month)) {
      error += txtTag + ", ";
    }

    if (error.length > 0) {
      console.log(error);
      return {
        date: null,
        error: error.substr(0, error.length - 1) + "!"
      };
    }

    return {
      date: new Date(year, month, day, hour, min, 0),
      error: null
    };
  } catch (e: any) {
    console.error(e);
    return {
      date: null,
      error: e
    };
  }
}

export function highlightDays(args: RenderDayCellEventArgs, that: Vue): void {
  /*Date need to be disabled*/
  if (args.element && args.date) {
    if (args.date.getDay() === 0 || args.date.getDay() === 6) {
      args.element.classList.add("weekendHighlight");
    }
    if (args.isOutOfRange) {
      return;
    }
    /*
          const holiday = this.$api.getHoliday(args.date);
          if (holiday) {
            ;
            args.element.classList.add("holydayHighlight");
            args.element.title = holiday.bezeichnung;
            const dayElement = args.element.children[0] as HTMLElement;
            dayElement.title = dayElement.title + "\r\n" + holiday.bezeichnung;
          } */
    if (args.element.classList.contains("e-other-month")) {
      return;
    }
    that.$api.checkHolidayRange(args.date).then((holiday: any) => {
      if (holiday && args.element) {
        args.element.classList.add("holydayHighlight");
        args.element.title = holiday.bezeichnung;
        const dayElement = args.element.children[0] as HTMLElement;
        dayElement.title = dayElement.title + "\r\n" + holiday.bezeichnung;
      }
    });
  }
}

export function getHolidayColorVuetify(date: Date | string) {
  const day = new Date(date);
  const colors = [];
  if (day.getDay() === 0 || day.getDay() === 6) {
    colors.push("rgb(150,150,150)");
  }

  const holiday = AUApi.Current.getHoliday(date);
  if (holiday) {
    colors.push("green");
  }
  return colors;
}

let weekdayNames: string[] = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag"
];
let weekdayNamesShort: string[] = [
  "So",
  "Mo",
  "Di",
  "Mi",
  "Do",
  "Fr",
  "Sa"
];
let monthNames: string[] = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember"
];
const monthNamesShort: string[] = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez"
];

let txtMinute = "Minute";
let txtStunde = "Stunde";
const txtSekunde = "Sekunde";
let txtTag = "Tag";
let txtMonat = "Monat";
let txtJahr = "Jahr";

export function getWeekdayNames() {
  return weekdayNames;
}
export function getWeekdayNamesShort() {
  return weekdayNamesShort;
}
export function getMonthNames() {
  return monthNames;
}
export function getMonthNamesShort() {
  return monthNamesShort;
}

export function initializeCalender(api: AUApi) {
  const weekdays = api.Code.filter((c: any) => c.gruppe === "Wochentag");

  const getWeekdayName = (kennung: string) => {
    const wd: any = weekdays.find((wwd: any) => wwd.kennung === kennung);
    if (!wd) {
      return "???";
    }
    return wd.bezeichnung;
  };

  let weekdayDigits = 2;
  if (api.user.sprache.toLowerCase() !== "de") {
    weekdayDigits = 3;
  }

  const getWeekdayNameShort = (kennung: string) => {
    return getWeekdayName(kennung).substr(0, weekdayDigits);
  };

  weekdayNames = [
    getWeekdayName("So"),
    getWeekdayName("Mo"),
    getWeekdayName("Di"),
    getWeekdayName("Mi"),
    getWeekdayName("Do"),
    getWeekdayName("Fr"),
    getWeekdayName("Sa")
  ];

  weekdayNamesShort = [
    getWeekdayNameShort("So"),
    getWeekdayNameShort("Mo"),
    getWeekdayNameShort("Di"),
    getWeekdayNameShort("Mi"),
    getWeekdayNameShort("Do"),
    getWeekdayNameShort("Fr"),
    getWeekdayNameShort("Sa")
  ];

  const months = api.Code.filter((c: any) => c.gruppe === "Monat");
  const getMonthName = (index: number) => {
    const month: any = months.find(
      (m: any) => m.kennung === "" + (index + 1)
    );
    if (month) {
      return month.bezeichnung;
    }
    return "?" + index + "?";
  };

  monthNames = [];
  for (let i = 0; i < 12; i++) {
    const nm = getMonthName(i);
    monthNames.push(nm);
    monthNamesShort.push(nm.substr(0, 3));
  }

  const getEinheitText = (kennung: string, fallback: string) => {
    const einheit: any = api.Einheiten.find(
      (c: any) => c.kennung === kennung
    );
    if (einheit) {
      return einheit.bezeichnung;
    }
    return fallback;
  };

  txtJahr = getEinheitText("y", "Jahr");
  txtMonat = getEinheitText("Monat", "Monat");
  // CalenderHelper.txtMonat = getEinheitText("w", "Woche");
  txtTag = getEinheitText("d", "Tag");
  txtStunde = getEinheitText("h", "Stunde");
  txtMinute = getEinheitText("min", "Minute");
  // CalenderHelper.txtSekunde = getEinheitText("Sekunde", "Sekunde");
}


declare interface DateParseReponse {
  date: Date | null;
  error: string | null;
}


export function formatDate(date: Date, format: string) {
  let formatted = format + "";
  const days = getWeekdayNamesShort();

  const weekday = days[date.getDay()];
  const week = ("00" + date.getWeekNumber().toString()).substr(-2);
  const year = date.getFullYear().toString();
  const year2digit = date
    .getFullYear()
    .toString()
    .substr(-2);
  const month2Digit = ("00" + (date.getMonth() + 1).toString()).substr(-2);
  const day2Digit = ("00" + date.getDate().toString()).substr(-2);
  const hour24 = ("00" + date.getHours()).substr(-2);
  const min = ("00" + date.getMinutes()).substr(-2);
  const sec = ("00" + date.getSeconds()).substr(-2);
  formatted = formatted.replace(/w/, week.toString());

  formatted = formatted.replace(/yyyy/, year);
  formatted = formatted.replace(/yy/, year2digit);
  formatted = formatted.replace(/MM/, month2Digit);
  formatted = formatted.replace(/dd/, day2Digit);

  formatted = formatted.replace(/HH/, hour24);
  formatted = formatted.replace(/mm/, min);
  formatted = formatted.replace(/ss/, sec);

  formatted = formatted.replace(/EE/, weekday);

  return formatted;
}
