









import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
@Component({
  provide: {},
})
export default class StammZeitmodell extends Vue {
  @Prop({ default: null }) public siteData: any;

  public mounted() {
    // debugger;
  }
}
