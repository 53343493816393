









import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  provide: {},
})
export default class StammAdressat extends Vue {
  @Prop({ default: null }) public siteData: any;
  public mounted() {
    // debugger;
  }
}
